import React, {useContext, useState, useEffect} from 'react'
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import myConfig from '../configs/config';
import axios from 'axios';
import { Description } from '@headlessui/react';
import { jwtDecode } from 'jwt-decode';
import AuthContext from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { createTheme, Skeleton, ThemeProvider, Alert, CircularProgress, Snackbar, LinearProgress } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 6,
    whiteSpace: 'nowrap',
    width: 1,
  });

export function CreateBlog() {
    const [value, setValue] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [title, setTitle] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [preview, setPreview] = useState(null);
    
    // Form validation states
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});
    
    // Toast notification states
    const [toast, setToast] = useState({
        open: false,
        message: '',
        severity: 'info'
    });

    // File upload loading state
    const [fileLoading, setFileLoading] = useState(false);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFileLoading(true);
            setSelectedFile(file);
            
            // Simulate delay to show loading effect
            setTimeout(() => {
                setPreview(URL.createObjectURL(file));
                setFileLoading(false);
                // Clear file error if it exists
                setErrors(prev => ({...prev, file: null}));
                setTouched(prev => ({...prev, file: true}));
            }, 500);
        }
    };

    const {authTokens, logoutUser} = useContext(AuthContext);

    const darkTheme = createTheme({
        palette: {
          mode: 'dark',
        },
    });

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline','strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link', 'image'],
            ['clean']
        ],
    };

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
    ];
    
    // Validate form
    const validateForm = () => {
        const newErrors = {};
        
        if (!title.trim()) {
            newErrors.title = 'Title is required';
        }
        
        if (!value.trim()) {
            newErrors.description = 'Blog content is required';
        }
        
        if (!selectedFile) {
            newErrors.file = 'Blog poster image is required';
        }
        
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Handle field blur for validation
    const handleBlur = (field) => {
        setTouched({...touched, [field]: true});
        
        if (field === 'title' && !title.trim()) {
            setErrors({...errors, title: 'Title is required'});
        } else if (field === 'title') {
            setErrors({...errors, title: null});
        }
        
        if (field === 'description' && !value.trim()) {
            setErrors({...errors, description: 'Blog content is required'});
        } else if (field === 'description') {
            setErrors({...errors, description: null});
        }
    };

    // Handle toast close
    const handleCloseToast = () => {
        setToast({...toast, open: false});
    };
    
    // Submit blog
    const handleSubmit = () => {
        if (!validateForm()) {
            setToast({
                open: true,
                message: 'Please fill all required fields',
                severity: 'error'
            });
            return;
        }
        
        setLoading(true);
        
        try {
            let form_data = new FormData();
            form_data.append('title', title);
            form_data.append('description', value);
            form_data.append('image', selectedFile);
            form_data.append('user', String(authTokens.access));
            const blogUrl = `${myConfig.CRU_URL}/account/blog/`;
            axios({
                baseURL: blogUrl,
                method: 'POST',
                headers: {
                    'Content-Type':'multipart/form-data',
                    'Authorization': `Bearer ${authTokens.access}`
                },
                data: form_data
            })
            .then((res) => {
                console.log(res.data);
                if(res.status === 200){
                    setToast({
                        open: true,
                        message: 'Blog created successfully!',
                        severity: 'success'
                    });
                    
                    // Navigate after a short delay to show success message
                    setTimeout(() => {
                        navigate('/blogs');
                    }, 1500);
                }else if(res.statusText === 'Unauthorized'){
                    logoutUser();
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logoutUser();
                } else {
                    setToast({
                        open: true,
                        message: 'Failed to create blog. Please try again later.',
                        severity: 'error'
                    });
                }
            })
            .finally(() => {
                setLoading(false);
            });
        } catch (error) {
            setLoading(false);
            setToast({
                open: true,
                message: 'An unexpected error occurred',
                severity: 'error'
            });
        }
    }

    return (
        <div className='relative h-full bg-black'>
            <div className="absolute duration-1000 animate-pulse bottom-0 left-0 right-0 top-0 bg-[linear-gradient(to_right,#4f4f4f2e_2px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:25px_24px]"></div>
            <div className="absolute left-0 right-0 top-0 h-full w-full rounded-3xl bg-[radial-gradient(circle_250px_at_50%_200px,#fbfbfb36,#000)]"></div>
            
            <Snackbar 
                open={toast.open} 
                autoHideDuration={6000} 
                onClose={handleCloseToast}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={handleCloseToast} severity={toast.severity} sx={{ width: '100%' }}>
                    {toast.message}
                </Alert>
            </Snackbar>
            
            <div className="flex justify-center items-center relative">
                <div className="md:w-3/4 md:p-10 md:my-10 rounded-md border bg-black/15 border-gray-600 shadow-md">
                    <div className="flex justify-between items-center mb-6">
                        <h1 className="text-2xl font-bold text-gray-200">Create Blog</h1>
                        {loading && (
                            <div className="flex items-center gap-2">
                                <CircularProgress size={20} color="primary" />
                                <span className="text-gray-300 text-sm">Saving your blog...</span>
                            </div>
                        )}
                    </div>
                    
                    {loading && <LinearProgress color="primary" className="mb-4" />}
                    
                    <ThemeProvider theme={darkTheme}>
                        <div className="border border-gray-700 rounded-lg p-4 mb-6 transition-all duration-300 hover:border-blue-500">
                            {fileLoading ? (
                                <div className="flex flex-col items-center justify-center h-60">
                                    <CircularProgress size={40} />
                                    <p className="mt-2 text-gray-400">Processing image...</p>
                                </div>
                            ) : preview ? (
                                <div className='flex items-center justify-center'>
                                    <img 
                                        src={preview} 
                                        alt="Selected preview" 
                                        className='w-auto h-60 object-fill rounded-md transition-all duration-300 hover:scale-[1.02]' 
                                    />
                                </div>
                            ) : (
                                <div className="flex flex-col items-center justify-center h-60 border-dashed border-2 border-gray-600 rounded-lg">
                                    <CloudUploadIcon sx={{ fontSize: 60, color: 'gray' }} />
                                    <p className="mt-2 text-gray-400">No image selected</p>
                                </div>
                            )}
                            
                            <Button 
                                component="label" 
                                role={undefined} 
                                variant="contained" 
                                tabIndex={-1} 
                                startIcon={<AddPhotoAlternateIcon />} 
                                style={{marginTop:'15px'}}
                                fullWidth
                                disabled={loading || fileLoading}
                                color={errors.file && touched.file ? "error" : "primary"}
                            >
                                {preview ? "Change Blog Poster" : "Select Blog Poster"}
                                <VisuallyHiddenInput type="file" accept="image/png, image/jpeg" onChange={handleFileChange}/>
                            </Button>
                            
                            {errors.file && touched.file && (
                                <p className="text-red-500 text-sm mt-1">{errors.file}</p>
                            )}
                        </div>

                        <Box component="div" className="mt-5" noValidate autoComplete="off">
                            <TextField 
                                type="text" 
                                placeholder="Blog Title" 
                                label="Enter Blog Title" 
                                value={title} 
                                onChange={(e) => setTitle(e.target.value)}
                                onBlur={() => handleBlur('title')} 
                                fullWidth 
                                required
                                disabled={loading}
                                error={Boolean(errors.title && touched.title)}
                                helperText={touched.title && errors.title ? errors.title : ''}
                                className="transition-all duration-300"
                            />
                        </Box>

                        <div className="mt-6">
                            <label htmlFor="blogContent" className="block text-sm font-semibold leading-6 text-gray-300 mb-2">
                                Write Blog Content
                                {errors.description && touched.description && (
                                    <span className="text-red-500 ml-2 text-xs">(Required)</span>
                                )}
                            </label>
                            <div className={`transition-all duration-300 ${errors.description && touched.description ? 'border-2 border-red-500 rounded-lg' : ''}`}>
                                <ReactQuill 
                                    className='rounded-lg h-full bg-white' 
                                    formats={formats} 
                                    modules={modules} 
                                    theme="snow" 
                                    value={value} 
                                    onChange={setValue}
                                    onBlur={() => handleBlur('description')}
                                    readOnly={loading}
                                />
                            </div>
                        </div>
                    </ThemeProvider>
                    
                    <div className="relative mt-8">
                        <button 
                            className={`text-gray-200 uppercase border-gray-600 border px-5 py-3 rounded-md font-semibold transition-all duration-300
                                ${loading ? 'bg-gray-700 cursor-not-allowed' : 'hover:bg-clip-text hover:text-transparent hover:bg-gradient-to-r hover:from-blue-400 hover:to-pink-600 hover:border-blue-400'}`}  
                            onClick={handleSubmit} 
                            disabled={loading}
                        >
                            {loading ? (
                                <div className="flex items-center justify-center gap-2">
                                    <CircularProgress size={20} color="inherit" />
                                    <span className="uppercase">Creating Blog...</span>
                                </div>
                            ) : (
                                'Create Blog'
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}