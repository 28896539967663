import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { UserIcon, StarIcon, ChatBubbleLeftRightIcon, CalendarIcon } from "@heroicons/react/20/solid";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import myConfig from "../../configs/config";
import axios from "axios";
import AuthContext from "../../configs/config";
import TextField from '@mui/material/TextField';
import { 
  createTheme, 
  Skeleton, 
  ThemeProvider, 
  Rating, 
  Button, 
  Chip, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Snackbar, 
  Alert, 
  Tab, 
  Tabs, 
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Stack,
  MenuItem,
  Select,
  InputLabel
} from '@mui/material';

// Remove the problematic imports
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';

export function ExpertProfile() {
  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState(true);
  const [firstName, setFirstName] = useState(''); 
  const [lastName, setLastName] = useState('');
  const [description, setDescription] = useState('');
  const [previewUrl, setPreviewUrl] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [openContact, setOpenContact] = useState(false);
  const [message, setMessage] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [reviews, setReviews] = useState([
    { id: 1, name: 'Shubham Musmade', rating: 5, comment: 'Extremely knowledgeable and helpful. Would definitely recommend!', date: '2023-04-12' },
    { id: 2, name: 'Rakesh Shinde', rating: 4, comment: 'Great expertise in blockchain technology. Solved my problem efficiently.', date: '2023-03-28' }
  ]);
  const [expertiseTags, setExpertiseTags] = useState(['Blockchain', 'Smart Contracts', 'Cryptocurrency', 'DeFi', 'NFTs']);
  const [rating, setRating] = useState(0);
  const [reviewText, setReviewText] = useState('');

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });
  
  useEffect(() => {
    getProfile();
  }, []);
  
  const { expertID } = useParams();

  let getProfile = async () => {
    let response = await fetch(`${myConfig.CRU_URL}/account/getexperts/${expertID}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    let data = await response.json();
    console.log(data);
    setProfile(data);
    setFirstName(data.first_name);
    setLastName(data.last_name);
    setPreviewUrl(data.user_profile);
    setDescription(data.description);
    setLoading(false);
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleContactOpen = () => {
    setOpenContact(true);
  };

  const handleContactClose = () => {
    setOpenContact(false);
  };

  const handleSendMessage = () => {
    // Here you would implement actual message sending logic
    console.log("Sending message:", message);
    setMessage('');
    handleContactClose();
    setSnackbar({ 
      open: true, 
      message: 'Message sent successfully! The expert will contact you soon.',
      severity: 'success'
    });
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleSubmitReview = () => {
    const newReview = {
      id: reviews.length + 1,
      name: 'You', // In a real app, use the logged-in user's name
      rating: rating,
      comment: reviewText,
      date: new Date().toISOString().split('T')[0]
    };
    
    setReviews([newReview, ...reviews]);
    setRating(0);
    setReviewText('');
    setSnackbar({ 
      open: true, 
      message: 'Thank you for your review!',
      severity: 'success'
    });
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`expert-tabpanel-${index}`}
        aria-labelledby={`expert-tab-${index}`}
        {...other}
        className="py-4"
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  // Custom loading animation CSS
  const shimmerAnimation = {
    animation: 'shimmer 1.5s infinite linear',
    background: 'linear-gradient(90deg, rgba(255,255,255,0.03) 0%, rgba(255,255,255,0.1) 50%, rgba(255,255,255,0.03) 100%)',
    backgroundSize: '200% 100%',
    borderRadius: '4px'
  };

  // Add these new states for booking session
  const [openBooking, setOpenBooking] = useState(false);
  const [bookingData, setBookingData] = useState({
    organizationName: '',
    sessionMode: 'online',
    location: '',
    date: '',
    time: '',
    notes: ''
  });

  const handleBookingOpen = () => {
    setOpenBooking(true);
  };

  const handleBookingClose = () => {
    setOpenBooking(false);
  };

  const handleBookingChange = (field, value) => {
    setBookingData({
      ...bookingData,
      [field]: value
    });
  };

  const handleBookSession = () => {
    // Here you would implement the actual booking logic with backend
    console.log("Booking session with data:", bookingData);
    handleBookingClose();
    setSnackbar({
      open: true,
      message: 'Session booked successfully! The expert will review and confirm shortly.',
      severity: 'success'
    });
    // Reset form data
    setBookingData({
      organizationName: '',
      sessionMode: 'online',
      location: '',
      date: '',
      time: '',
      notes: ''
    });
  };

  const SessionButton = () => (
    <Button 
      variant="outlined" 
      startIcon={<CalendarIcon className="h-5 w-5" />}
      onClick={handleBookingOpen}
      sx={{ 
          borderColor: '#6b7280',
          color: '#d1d5db',
          '&:hover': {
              borderColor: '#9ca3af',
              backgroundColor: 'rgba(255, 255, 255, 0.05)'
          }
      }}
    >
      Book Session
    </Button>
  );
  
  return (
    <div className="w-full relative mx-auto bg-black py-20 md:px-14 px-5">
        <div className="absolute bottom-0 left-0 right-0 top-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:25px_24px]"></div>
        <div className="absolute left-0 right-0 top-0 h-full w-full rounded-3xl bg-[radial-gradient(circle_400px_at_50%_300px,#fbfbfb36,#000)]"></div>
      
      <div className="grid relative md:grid-cols-2 mx-auto border w-fit border-gray-600 backdrop-blur-sm rounded-lg justify-center">
        <div className="p-8 flex flex-col items-center">
            {loading ? (
                <div className="flex flex-col items-center w-full">
                  <Skeleton 
                    variant="circular" 
                    width={200} 
                    height={200} 
                    animation="wave"
                    sx={{
                      bgcolor: 'rgba(64, 64, 64, 0.2)',
                      ...shimmerAnimation
                    }} 
                    className="mx-auto mb-6" 
                  />
                  <div className="mt-6 flex flex-wrap gap-2 justify-center w-full">
                    {[1, 2, 3, 4, 5].map((i) => (
                      <Skeleton 
                        key={i} 
                        variant="rounded" 
                        width={80} 
                        height={32} 
                        animation="wave"
                        sx={{
                          bgcolor: 'rgba(64, 64, 64, 0.2)',
                          ...shimmerAnimation,
                          margin: '4px'
                        }} 
                      />
                    ))}
                  </div>
                  <div className="mt-6 flex space-x-2">
                    <Skeleton 
                      variant="rounded" 
                      width={120} 
                      height={40} 
                      animation="wave"
                      sx={{
                        bgcolor: 'rgba(64, 64, 64, 0.2)',
                        ...shimmerAnimation
                      }} 
                    />
                    <Skeleton 
                      variant="rounded" 
                      width={120} 
                      height={40} 
                      animation="wave"
                      sx={{
                        bgcolor: 'rgba(64, 64, 64, 0.2)',
                        ...shimmerAnimation
                      }} 
                    />
                  </div>
                </div>
            ) : (
                <>
                    {previewUrl ? 
                        <img src={previewUrl} className="w-80 h-auto mx-auto z-10 object-cover rounded-full transition-transform hover:scale-105 duration-300" alt="User Profile"/> : 
                        <UserIcon className="h-56 w-56 mx-auto text-gray-400 rounded-full" alt="User Profile"/>
                    }
                    
                    <div className="mt-6 flex flex-wrap gap-2 justify-center">
                        {expertiseTags.map((tag, index) => (
                            <Chip
                                key={index}
                                label={tag}
                                variant="outlined"
                                className="cursor-pointer hover:bg-gray-700 transition-colors"
                                sx={{ color: '#9ca3af', borderColor: '#4b5563', margin: '4px' }}
                                onClick={() => {
                                    setSnackbar({ 
                                        open: true, 
                                        message: `Expert in ${tag}`, 
                                        severity: 'info' 
                                    });
                                }}
                            />
                        ))}
                    </div>
                    
                    <div className="mt-6 space-x-2">
                        <Button 
                            variant="contained" 
                            startIcon={<ChatBubbleLeftRightIcon className="h-5 w-5" />}
                            onClick={handleContactOpen}
                            sx={{ 
                                background: 'linear-gradient(to right, #2563eb, #db2777)',
                                '&:hover': {
                                    background: 'linear-gradient(to right, #1d4ed8, #be185d)'
                                }
                            }}
                        >
                            Contact
                        </Button>
                        <SessionButton />
                    </div>
                </>
            )}
        </div>

        <div className="md:p-10 p-5 shadow-md text-gray-200">
            <div className="uppercase tracking-wide text-sm font-semibold text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-pink-500 w-fit mb-4">Expert Profile</div>
            
            {loading ? (
                <div className="space-y-4">
                  {/* Name skeleton */}
                  <Skeleton 
                    variant="text" 
                    width={180} 
                    height={40} 
                    animation="wave"
                    sx={{
                      bgcolor: 'rgba(64, 64, 64, 0.2)',
                      ...shimmerAnimation
                    }} 
                  />
                  
                  {/* Rating skeleton */}
                  <div className="flex items-center mb-4">
                    <Skeleton 
                      variant="rounded" 
                      width={120} 
                      height={24} 
                      animation="wave"
                      sx={{
                        bgcolor: 'rgba(64, 64, 64, 0.2)',
                        ...shimmerAnimation
                      }} 
                    />
                    <Skeleton 
                      variant="text" 
                      width={60} 
                      height={24} 
                      animation="wave"
                      sx={{
                        bgcolor: 'rgba(64, 64, 64, 0.2)',
                        ...shimmerAnimation,
                        marginLeft: '8px'
                      }} 
                    />
                  </div>
                  
                  {/* Tabs skeleton */}
                  <div className="flex mb-4">
                    {[1, 2, 3].map((i) => (
                      <Skeleton 
                        key={i} 
                        variant="rounded" 
                        width={100} 
                        height={40} 
                        animation="wave"
                        sx={{
                          bgcolor: 'rgba(64, 64, 64, 0.2)',
                          ...shimmerAnimation,
                          marginRight: '8px'
                        }} 
                      />
                    ))}
                  </div>
                  
                  {/* Content skeleton */}
                  <div className="mt-4">
                    <Skeleton 
                      variant="rounded" 
                      width="100%" 
                      height={20} 
                      animation="wave"
                      sx={{
                        bgcolor: 'rgba(64, 64, 64, 0.2)',
                        ...shimmerAnimation,
                        marginBottom: '8px'
                      }} 
                    />
                    <Skeleton 
                      variant="rounded" 
                      width="95%" 
                      height={20} 
                      animation="wave"
                      sx={{
                        bgcolor: 'rgba(64, 64, 64, 0.2)',
                        ...shimmerAnimation,
                        marginBottom: '8px'
                      }} 
                    />
                    <Skeleton 
                      variant="rounded" 
                      width="90%" 
                      height={20} 
                      animation="wave"
                      sx={{
                        bgcolor: 'rgba(64, 64, 64, 0.2)',
                        ...shimmerAnimation,
                        marginBottom: '8px'
                      }} 
                    />
                    <Skeleton 
                      variant="rounded" 
                      width="85%" 
                      height={20} 
                      animation="wave"
                      sx={{
                        bgcolor: 'rgba(64, 64, 64, 0.2)',
                        ...shimmerAnimation
                      }} 
                    />
                  </div>
                </div>
            ) : (
                <ThemeProvider theme={darkTheme}>
                    <div>
                        <h2 className="text-2xl font-semibold mb-2 transition-colors hover:text-blue-400">
                            {firstName} {lastName}
                        </h2>

                        <div className="flex items-center mb-6">
                            <Rating 
                                value={4.5} 
                                precision={0.5} 
                                readOnly 
                                sx={{ color: '#facc15' }}
                            />
                            <span className="ml-2 text-gray-400">4.5 (18 reviews)</span>
                        </div>

                        <Tabs 
                            value={currentTab} 
                            onChange={handleTabChange}
                            indicatorColor="primary"
                            textColor="inherit"
                            variant="fullWidth"
                            sx={{
                                '& .MuiTab-root': {
                                    color: '#9ca3af',
                                    '&.Mui-selected': {
                                        color: '#ffffff',
                                    }
                                },
                                '& .MuiTabs-indicator': {
                                    backgroundColor: '#3b82f6',
                                }
                            }}
                        >
                            <Tab label="About" />
                            <Tab label="Reviews" />
                            <Tab label="Experience" />
                        </Tabs>

                        <TabPanel value={currentTab} index={0}>
                            <p className="text-gray-300 leading-relaxed">
                                {description || "No description available for this expert."}
                            </p>
                        </TabPanel>

                        <TabPanel value={currentTab} index={1}>
                            <div className="mb-6 p-4 border border-gray-700 rounded-lg bg-gray-800/30">
                                <h3 className="mb-3 text-lg font-medium">Leave a Review</h3>
                                <div className="mb-3 flex items-center">
                                    <span className="mr-2">Your Rating:</span>
                                    <Rating 
                                        value={rating} 
                                        onChange={(e, newValue) => setRating(newValue)}
                                        sx={{ color: '#facc15' }}
                                    />
                                </div>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={3}
                                    variant="outlined"
                                    placeholder="Share your experience with this expert..."
                                    value={reviewText}
                                    onChange={(e) => setReviewText(e.target.value)}
                                    className="mb-3"
                                />
                                <Button 
                                    variant="contained" 
                                    onClick={handleSubmitReview}
                                    disabled={!rating || !reviewText}
                                    sx={{ 
                                        background: 'linear-gradient(to right, #2563eb, #db2777)',
                                        '&:hover': {
                                            background: 'linear-gradient(to right, #1d4ed8, #be185d)'
                                        }
                                    }}
                                >
                                    Submit Review
                                </Button>
                            </div>

                            <div className="space-y-4">
                                {reviews.map(review => (
                                    <div key={review.id} className="p-4 border border-gray-700 rounded-lg transition-transform hover:scale-[1.01] hover:border-gray-500">
                                        <div className="flex justify-between items-start mb-2">
                                            <div>
                                                <h4 className="font-medium">{review.name}</h4>
                                                <Rating value={review.rating} readOnly size="small" sx={{ color: '#facc15' }} />
                                            </div>
                                            <span className="text-xs text-gray-400">{review.date}</span>
                                        </div>
                                        <p className="text-gray-300">{review.comment}</p>
                                    </div>
                                ))}
                            </div>
                        </TabPanel>

                        <TabPanel value={currentTab} index={2}>
                            <div className="space-y-4">
                                <div className="p-4 border border-gray-700 rounded-lg">
                                    <h4 className="font-medium mb-1">Senior Blockchain Developer</h4>
                                    <p className="text-gray-400 text-sm mb-2">2020 - Present</p>
                                    <p className="text-gray-300">Led development of smart contract systems for decentralized applications with focus on security and efficiency.</p>
                                </div>
                                <div className="p-4 border border-gray-700 rounded-lg">
                                    <h4 className="font-medium mb-1">Blockchain Consultant</h4>
                                    <p className="text-gray-400 text-sm mb-2">2018 - 2020</p>
                                    <p className="text-gray-300">Provided expert advice to startups and enterprises on blockchain implementation and strategy.</p>
                                </div>
                            </div>
                        </TabPanel>
                    </div>
                </ThemeProvider>
            )}
        </div>
      </div>

      {/* Contact Dialog */}
      <Dialog open={openContact} onClose={handleContactClose}>
        <DialogTitle sx={{ bgcolor: '#1f2937', color: 'white' }}>
          Contact {firstName} {lastName}
        </DialogTitle>
        <DialogContent sx={{ bgcolor: '#1f2937', color: 'white', pt: 2 }}>
          <TextField
            autoFocus
            margin="dense"
            id="message"
            label="Your Message"
            type="text"
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root': {
                color: 'white',
                '& fieldset': {
                  borderColor: 'rgba(255, 255, 255, 0.23)',
                },
                '&:hover fieldset': {
                  borderColor: 'rgba(255, 255, 255, 0.5)',
                },
              },
              '& .MuiInputLabel-root': {
                color: 'rgba(255, 255, 255, 0.7)',
              },
            }}
          />
        </DialogContent>
        <DialogActions sx={{ bgcolor: '#1f2937', p: 2 }}>
          <Button onClick={handleContactClose} sx={{ color: '#9ca3af' }}>Cancel</Button>
          <Button 
            onClick={handleSendMessage}
            disabled={!message.trim()} 
            sx={{ 
              background: 'linear-gradient(to right, #2563eb, #db2777)',
              color: 'white',
              '&:hover': {
                background: 'linear-gradient(to right, #1d4ed8, #be185d)'
              }
            }}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>

      {/* Book Session Dialog */}
      <Dialog 
        open={openBooking} 
        onClose={handleBookingClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle sx={{ bgcolor: '#1f2937', color: 'white', borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>
          Book a Session with {firstName} {lastName}
        </DialogTitle>
        <DialogContent sx={{ bgcolor: '#1f2937', color: 'white', pt: 3 }}>
          <Stack spacing={3}>
            <TextField
              autoFocus
              label="Organization Name"
              fullWidth
              variant="outlined"
              value={bookingData.organizationName}
              onChange={(e) => handleBookingChange('organizationName', e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  color: 'white',
                  '& fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.23)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.5)',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'rgba(255, 255, 255, 0.7)',
                },
              }}
            />

            <FormControl sx={{ my: 1 }}>
              <FormLabel id="session-mode-label" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                Session Mode
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="session-mode-label"
                name="session-mode"
                value={bookingData.sessionMode}
                onChange={(e) => handleBookingChange('sessionMode', e.target.value)}
              >
                <FormControlLabel 
                  value="online" 
                  control={<Radio sx={{ color: 'rgba(255, 255, 255, 0.7)', '&.Mui-checked': { color: '#3b82f6' } }} />} 
                  label="Online" 
                  sx={{ color: 'white' }}
                />
                <FormControlLabel 
                  value="offline" 
                  control={<Radio sx={{ color: 'rgba(255, 255, 255, 0.7)', '&.Mui-checked': { color: '#3b82f6' } }} />}
                  label="Offline" 
                  sx={{ color: 'white' }}
                />
              </RadioGroup>
            </FormControl>

            {bookingData.sessionMode === 'offline' && (
              <TextField
                label="Location"
                fullWidth
                variant="outlined"
                placeholder="Enter address for in-person meeting"
                value={bookingData.location}
                onChange={(e) => handleBookingChange('location', e.target.value)}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    color: 'white',
                    '& fieldset': {
                      borderColor: 'rgba(255, 255, 255, 0.23)',
                    },
                    '&:hover fieldset': {
                      borderColor: 'rgba(255, 255, 255, 0.5)',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: 'rgba(255, 255, 255, 0.7)',
                  },
                }}
              />
            )}

            {/* Replace date time picker with separate date and time inputs */}
            <TextField
              label="Date"
              type="date"
              fullWidth
              variant="outlined"
              value={bookingData.date}
              onChange={(e) => handleBookingChange('date', e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: new Date().toISOString().split('T')[0] // Today's date as minimum
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  color: 'white',
                  '& fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.23)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.5)',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'rgba(255, 255, 255, 0.7)',
                },
              }}
            />

            <TextField
              label="Time"
              type="time"
              fullWidth
              variant="outlined"
              value={bookingData.time}
              onChange={(e) => handleBookingChange('time', e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  color: 'white',
                  '& fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.23)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.5)',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'rgba(255, 255, 255, 0.7)',
                },
              }}
            />

            <TextField
              label="Additional Notes"
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              placeholder="Any specific topics or requirements for the session..."
              value={bookingData.notes}
              onChange={(e) => handleBookingChange('notes', e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  color: 'white',
                  '& fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.23)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.5)',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'rgba(255, 255, 255, 0.7)',
                },
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ bgcolor: '#1f2937', p: 2, borderTop: '1px solid rgba(255, 255, 255, 0.1)' }}>
          <Button onClick={handleBookingClose} sx={{ color: '#9ca3af' }}>
            Cancel
          </Button>
          <Button 
            onClick={handleBookSession}
            disabled={!bookingData.organizationName || (bookingData.sessionMode === 'offline' && !bookingData.location) || !bookingData.date || !bookingData.time} 
            sx={{ 
              background: 'linear-gradient(to right, #2563eb, #db2777)',
              color: 'white',
              '&:hover': {
                background: 'linear-gradient(to right, #1d4ed8, #be185d)'
              },
              '&.Mui-disabled': {
                background: 'rgba(255, 255, 255, 0.12)',
                color: 'rgba(255, 255, 255, 0.3)'
              }
            }}
          >
            Book Now
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      
      {/* Add shimmer animation keyframes */}
      <style jsx global>{`
        @keyframes shimmer {
          0% {
            background-position: -200% 0;
          }
          100% {
            background-position: 200% 0;
          }
        }
      `}</style>
    </div>
  );
}
