import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import myConfig from '../configs/config';
import { createTheme, Skeleton, ThemeProvider, CircularProgress, Snackbar, Alert, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactPage = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [notification, setNotification] = useState({ open: false, message: '', type: 'success' });
    const [errors, setErrors] = useState({ name: '', email: '', message: '' });
    const navigate = useNavigate();

    const darkTheme = createTheme({
        palette: {
          mode: 'dark',
        },
    });

    // Validate email format
    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    // Validate form fields
    const validateForm = () => {
        const newErrors = {
            name: name ? '' : 'Name is required',
            email: email ? (validateEmail(email) ? '' : 'Please enter a valid email') : 'Email is required',
            message: message ? '' : 'Message is required'
        };
        
        setErrors(newErrors);
        return !newErrors.name && !newErrors.email && !newErrors.message;
    };

    // Handle notification close
    const handleCloseNotification = () => {
        setNotification({ ...notification, open: false });
    };

    // Submit contact form
    const handleSubmit = () => {
        if (!validateForm()) {
            toast.error("Please fill in all required fields correctly.", {
                position: "bottom-center",
                autoClose: 3000,
            });
            return;
        }
        
        setLoading(true);
        const contactUrl = `${myConfig.CRU_URL}/courses/contact/`;
        
        const contactData = {
            name: name,
            email: email,
            message: message
        };

        axios({
            baseURL: contactUrl,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            data: contactData
        })
        .then((res) => {
            console.log(res);
            if (res.status === 200) {
                toast.success('Thank you for contacting us! We will get back to you soon.', {
                    position: "bottom-center",
                    autoClose: 3000,
                });
                // Reset form
                setName('');
                setEmail('');
                setMessage('');
                // Navigate after a slight delay
                setTimeout(() => navigate('/'), 3000);
            }
        })
        .catch((error) => {
            console.error("There was an error sending the message:", error);
            toast.error('Failed to send message. Please try again later.', {
                position: "bottom-center",
                autoClose: 3000,
            });
        })
        .finally(() => {
            setLoading(false);
        });
    };

    return (
        <div className="relative h-full bg-black ">
            <div class="absolute bottom-0 left-0 right-0 top-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:25px_24px]"></div>
            <div class="absolute left-0 right-0 top-0 h-full w-full rounded-3xl bg-[radial-gradient(circle_400px_at_50%_300px,#fbfbfb36,#000)]"></div>
            
            <div className=" relative mx-auto max-w-2xl py-20 ">
                <motion.div 
                    className="text-center"
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                >
                    <h4 className="font-bold  uppercase transition-transform duration-300 hover:scale-110 tracking-wide text-gray-200 md:text-5xl text-3xl hover:bg-clip-text hover:text-transparent hover:bg-gradient-to-r hover:from-blue-500 hover:to-pink-500">
                    Contact BlockChain  <br/>Pathshala
                    </h4>
                </motion.div>
            </div>
            <motion.div 
                className="md:mx-10 mx-4 relative md:p-10 p-5 rounded-lg border border-gray-600"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.3 }}
            >
                <ThemeProvider theme={darkTheme}>
                <div className='md:flex gap-5'>
                    <Box component="div" className="mt-5 flex-1" noValidate autoComplete="off">
                        <TextField
                            type="text"
                            placeholder="Your Name"
                            label="Name"
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value);
                                if (errors.name) setErrors({...errors, name: ''});
                            }}
                            fullWidth
                            required
                            error={Boolean(errors.name)}
                            helperText={errors.name}
                            disabled={loading}
                            autoFocus
                            InputProps={{
                                sx: { '&.Mui-focused': { transform: 'scale(1.02)', transition: 'transform 0.2s' } }
                            }}
                        />
                    </Box>

                    <Box component="div" className="mt-5 flex-1" noValidate autoComplete="off">
                        <TextField
                            type="email"
                            placeholder="Your Email"
                            label="Email"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                                if (errors.email) setErrors({...errors, email: ''});
                            }}
                            fullWidth
                            required
                            error={Boolean(errors.email)}
                            helperText={errors.email}
                            disabled={loading}
                            InputProps={{
                                sx: { '&.Mui-focused': { transform: 'scale(1.02)', transition: 'transform 0.2s' } }
                            }}
                        />
                    </Box>
                </div>

                <Box component="div" className="mt-5" noValidate autoComplete="off">
                    <TextField
                        type="text"
                        placeholder="Your Message"
                        className=''
                        label="Message"
                        value={message}
                        onChange={(e) => {
                            setMessage(e.target.value);
                            if (errors.message) setErrors({...errors, message: ''});
                        }}
                        multiline
                        rows={4}
                        fullWidth
                        required
                        error={Boolean(errors.message)}
                        helperText={errors.message ? errors.message : `${message.length}/500 characters`}
                        disabled={loading}
                        inputProps={{ maxLength: 500 }}
                        InputProps={{
                            sx: { '&.Mui-focused': { transform: 'scale(1.02)', transition: 'transform 0.2s' } }
                        }}
                    />
                </Box>
                </ThemeProvider>

                <div className="relative mt-8 flex">
                    <button 
                        className={`border border-gray-600 px-6 bg-black py-4 text-gray-200 transition-all duration-300 ${loading ? 'opacity-70 cursor-not-allowed' : 'hover:bg-gray-900'}`} 
                        variant="outlined" 
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        {loading ? (
                            <div className="flex items-center">
                                <CircularProgress size={20} color="inherit" className="mr-2" />
                                <span>Sending...</span>
                            </div>
                        ) : 'Send Message'}
                    </button>
                </div>

                {/* React Toastify Container */}
                <ToastContainer 
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                />
            </motion.div>
        </div>
    );
};

export default ContactPage;
