import { AcademicCapIcon } from "@heroicons/react/20/solid"
import { Link, Navigate, useNavigate  } from "react-router-dom"
import myConfig from "../configs/config"
import { Bounce, ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios"
import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Meteors } from "../components/animation/meteor";

export function CreateAccount() {
    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone_number, setPhoneNumber] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [referCode, setReferCode] = useState("");
    const [terms, setTerms] = useState(false);

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const [inputErrors, setInputErrors] = useState({
        first_name: false,
        last_name: false,
        email: false,
        phone_number: false,
        password: false,
        confirmPassword: false,
    });

    // Track if a field has been touched (for validation UX)
    const [touched, setTouched] = useState({
        first_name: false,
        last_name: false,
        email: false,
        phone_number: false,
        password: false,
        confirmPassword: false,
    });

    // Dialog
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAcceptTerms = () => {
        setTerms(true);
        handleClose();
        
        // Show toast notification
        toast.success('Terms and conditions accepted!', {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
        });
    };

    // Email validation function
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    // Phone number validation
    const validatePhoneNumber = (phone) => {
        const phoneRegex = /^\d{10,}$/;
        return phoneRegex.test(phone);
    };

    // Password validation function
    const validatePassword = (password) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return passwordRegex.test(password);
    };

    // Validate input fields
    const validateInput = (name, value) => {
        switch(name) {
            case "email":
                return validateEmail(value);
            case "phone_number": 
                return validatePhoneNumber(value);
            case "password":
                return validatePassword(value);
            case "confirmPassword":
                return value === password && value !== "";
            default:
                return value.trim() !== "";
        }
    };

    // Handle field changes and validation
    const handleInputChange = (name, value) => {
        // Mark field as touched
        setTouched(prev => ({
            ...prev,
            [name]: true
        }));

        const isValid = validateInput(name, value);
        setInputErrors((prevErrors) => ({
            ...prevErrors,
            [name]: !isValid
        }));

        // Update the corresponding state
        switch (name) {
            case "first_name":
                setFirstName(value);
                break;
            case "last_name":
                setLastName(value);
                break;
            case "email":
                setEmail(value);
                break;
            case "phone_number":
                setPhoneNumber(value);
                break;
            case "password":
                setPassword(value);
                // Also check confirm password validity when password changes
                if (confirmPassword) {
                    setInputErrors(prev => ({
                        ...prev,
                        confirmPassword: value !== confirmPassword
                    }));
                }
                break;
            case "confirmPassword":
                setConfirmPassword(value);
                break;
            default:
                break;
        }
    };

    const handleSave = (e) => {
        e.preventDefault();
        
        // Mark all fields as touched for validation
        const allTouched = Object.keys(touched).reduce((acc, key) => {
            acc[key] = true;
            return acc;
        }, {});
        setTouched(allTouched);
        
        // Validate all fields
        const validationErrors = {
            first_name: !validateInput("first_name", first_name),
            last_name: !validateInput("last_name", last_name),
            email: !validateInput("email", email),
            phone_number: !validateInput("phone_number", phone_number),
            password: !validateInput("password", password),
            confirmPassword: !validateInput("confirmPassword", confirmPassword),
        };
        
        setInputErrors(validationErrors);
        
        const hasErrors = Object.values(validationErrors).some((error) => error);
    
        if (hasErrors) {
            toast.error("Please correct the highlighted fields.", {
                position: "top-right",
                theme: "dark",
                transition: Bounce,
            });
            return;
        }
    
        if (!terms) {
            toast.warning("Please accept the terms and conditions.", {
                position: "top-right",
                theme: "dark",
                transition: Bounce,
            });
            return;
        }
    
        setLoading(true);
    
        const SIGNUP_URL = `${myConfig.CRU_URL}/account/register/`;
        axios({
            baseURL: SIGNUP_URL,
            method: "POST",
            data: {
                first_name: first_name,
                last_name: last_name,
                email: email,
                phone_number: phone_number,
                password: password,
                password2: confirmPassword,
                refferal_code: referCode,
                terms: true,
            },
        })
        .then((res) => {
            if (res.status === 200) {
                if(res.data.message ==="This email already exists."){
                    toast.warn('This email already exists.', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        transition: Bounce,
                    });
                }else{
                    toast.success('Account created successfully. Kindly verify your email', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        transition: Bounce,
                        });
                    setTimeout(() => {
                        navigate('/login');
                    }, 1000);
                }
            }
        })
        .catch((error) => {
            console.log("ERROR", error);
            let error_response = error.response?.data;
            if (error_response?.password) {
                toast.warn('Weak Password.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    transition: Bounce,
                });
            } else {
                toast.warn('An error occurred. Please try again.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    transition: Bounce,
                });
            }
        })
        .finally(() => {
            setLoading(false);
        });
    };
    
    const getInputClassName = (name) => {
        // Only show validation styling if field has been touched
        if (!touched[name]) return "bg-gray-100/20 border border-gray-600 text-gray-300 text-sm rounded-sm block w-full p-2.5 transition-all duration-300";
        
        return inputErrors[name]
            ? "bg-gray-100/20 border border-red-800 text-gray-300 text-sm rounded-sm block w-full p-2.5 focus:ring-2 focus:ring-inset focus:ring-red-800 transition-all duration-300"
            : "bg-gray-100/20 border border-green-800 text-gray-300 text-sm rounded-sm block w-full p-2.5 focus:ring-2 focus:ring-inset focus:ring-green-500 transition-all duration-300";
    };

    // Validation message component
    const ValidationMessage = ({ show, message }) => (
        <AnimatePresence>
            {show && (
                <motion.p
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    className="text-xs text-red-500 mt-1"
                >
                    {message}
                </motion.p>
            )}
        </AnimatePresence>
    );

    return (
        <section className="relative bg-black">
            <div className="absolute bottom-0 left-0 right-0 top-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:25px_24px]"></div>
            <div className="absolute left-0 right-0 top-0 h-full w-full rounded-3xl bg-[radial-gradient(circle_400px_at_50%_300px,#fbfbfb36,#000)]"></div>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" transition={Bounce}/>
            <div className="flex relative flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">
                <motion.div
                    initial={{ y: -20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.5 }}
                >
                    <Link to={"#"} className="flex group relative items-center mb-6 text-4xl font-bold tracking-tight text-gray-200 transition-transform duration-300 hover:scale-110 hover:bg-clip-text hover:text-transparent hover:bg-gradient-to-r hover:from-blue-500 hover:to-pink-500">
                        <AcademicCapIcon className="md:h-8 h-16 w-auto text-gray-300 group-hover:text-blue-500" aria-hidden="true" />
                        BlockChain Pathshala
                    </Link>
                </motion.div>
                <Meteors />
                <motion.div 
                    className="relative bg-transparent/10 backdrop-blur-sm border border-gray-600 rounded-md md:w-3/4 w-full"
                    initial={{ opacity: 0, scale: 0.95 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                >
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-200 md:text-2xl">
                            Create New Account
                        </h1>
                        <form className="" onSubmit={handleSave}>
                            <div className="md:grid md:grid-cols-2 gap-6 justify-center">
                                <div>
                                    <label htmlFor="first_name" className="block mb-2 text-sm text-gray-200 uppercase font-semibold">First Name</label>
                                    <motion.input 
                                        type="text" 
                                        name="first_name" 
                                        id="first_name" 
                                        className={getInputClassName("first_name")}
                                        placeholder="First Name" 
                                        value={first_name} 
                                        onChange={(e) => handleInputChange("first_name", e.target.value)} 
                                        onBlur={() => setTouched(prev => ({...prev, first_name: true}))}
                                        whileFocus={{ scale: 1.02 }}
                                        required
                                    />
                                    <ValidationMessage 
                                        show={touched.first_name && inputErrors.first_name} 
                                        message="Please enter your first name" 
                                    />
                                </div>
                                <div>
                                    <label htmlFor="last_name" className="block mb-2 text-sm text-gray-200 uppercase font-semibold">Last Name</label>
                                    <motion.input 
                                        type="text" 
                                        name="last_name" 
                                        id="last_name" 
                                        className={getInputClassName("last_name")}
                                        placeholder="Last Name" 
                                        value={last_name} 
                                        onChange={(e) => handleInputChange("last_name", e.target.value)} 
                                        onBlur={() => setTouched(prev => ({...prev, last_name: true}))}
                                        whileFocus={{ scale: 1.02 }}
                                        required
                                    />
                                    <ValidationMessage 
                                        show={touched.last_name && inputErrors.last_name} 
                                        message="Please enter your last name" 
                                    />
                                </div>
                                <div>
                                    <label htmlFor="email" className="block mb-2 text-sm text-gray-200 uppercase font-semibold">Your Email</label>
                                    <motion.input 
                                        type="email" 
                                        name="email" 
                                        id="email" 
                                        className={getInputClassName("email")}
                                        placeholder="xyz@mail.com" 
                                        value={email} 
                                        onChange={(e) => handleInputChange("email", e.target.value)} 
                                        onBlur={() => setTouched(prev => ({...prev, email: true}))}
                                        whileFocus={{ scale: 1.02 }}
                                        required
                                    />
                                    <ValidationMessage 
                                        show={touched.email && inputErrors.email} 
                                        message="Please enter a valid email address" 
                                    />
                                </div>
                                <div>
                                    <label htmlFor="phone_number" className="block mb-2 text-sm text-gray-200 uppercase font-semibold">Your Phone Number</label>
                                    <motion.input 
                                        type="tel" 
                                        name="phone_number" 
                                        id="phone_number" 
                                        className={getInputClassName("phone_number")}
                                        placeholder="91.........." 
                                        value={phone_number} 
                                        onChange={(e) => handleInputChange("phone_number", e.target.value)} 
                                        onBlur={() => setTouched(prev => ({...prev, phone_number: true}))}
                                        whileFocus={{ scale: 1.02 }}
                                        required
                                    />
                                    <ValidationMessage 
                                        show={touched.phone_number && inputErrors.phone_number} 
                                        message="Please enter a valid 10-digit phone number" 
                                    />
                                </div>
                                <div>
                                    <label htmlFor="password" className="block mb-2 text-sm text-gray-200 uppercase font-semibold">Password</label>
                                    <motion.input 
                                        type="password" 
                                        name="password" 
                                        id="password" 
                                        placeholder="••••••••" 
                                        className={getInputClassName("password")}
                                        value={password} 
                                        onChange={(e) => handleInputChange("password", e.target.value)} 
                                        onBlur={() => setTouched(prev => ({...prev, password: true}))}
                                        whileFocus={{ scale: 1.02 }}
                                        required
                                    />
                                    <ValidationMessage 
                                        show={touched.password && inputErrors.password} 
                                        message="Password must contain at least 8 characters, including uppercase, lowercase, number, and special character" 
                                    />
                                </div>
                                <div>
                                    <label htmlFor="confirm-password" className="block mb-2 text-sm text-gray-200 uppercase font-semibold">Confirm password</label>
                                    <motion.input 
                                        type="password" 
                                        name="confirm-password" 
                                        id="confirm-password" 
                                        placeholder="••••••••" 
                                        className={getInputClassName("confirmPassword")}
                                        value={confirmPassword} 
                                        onChange={(e) => handleInputChange("confirmPassword", e.target.value)} 
                                        onBlur={() => setTouched(prev => ({...prev, confirmPassword: true}))}
                                        whileFocus={{ scale: 1.02 }}
                                        required
                                    />
                                    <ValidationMessage 
                                        show={touched.confirmPassword && inputErrors.confirmPassword} 
                                        message="Passwords do not match" 
                                    />
                                </div>
                            </div>
                            <div className="flex items-center justify-center md:mt-0 mt-5">
                                <div className="space-y-4 w-full">
                                    <motion.div 
                                        className="flex items-center h-5 mt-4"
                                        whileHover={{ scale: 1.02 }}
                                    >
                                        <input 
                                            id="terms" 
                                            name="terms" 
                                            aria-describedby="terms" 
                                            type="checkbox" 
                                            className="w-4 h-4 accent-blue-500" 
                                            checked={terms} 
                                            onChange={(e) => setTerms(e.target.checked)} 
                                            required
                                        />
                                        <div className="ml-3 text-sm">
                                            <label htmlFor="terms" className="font-light text-gray-300">
                                                I accept the{" "}
                                                <motion.span 
                                                    className="font-medium text-blue-500 hover:underline cursor-pointer"
                                                    whileHover={{ scale: 1.05 }}
                                                    onClick={handleClickOpen}
                                                >
                                                    Terms and Conditions
                                                </motion.span>
                                            </label>
                                        </div>
                                    </motion.div>
                                    <motion.button 
                                        type="submit" 
                                        className="w-full text-gray-200 border border-gray-500 hover:bg-clip-text hover:bg-gradient-to-r hover:from-blue-500 hover:to-pink-500 hover:text-transparent transition-all duration-500 hover:scale-110 rounded-sm text-sm px-5 py-2.5 text-center hover:font-bold"
                                        disabled={loading}
                                        whileHover={{ scale: 1.03 }}
                                        whileTap={{ scale: 0.98 }}
                                    >
                                        {loading ? (
                                            <div className="flex items-center justify-center">
                                                <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-blue-500 mr-2"></div>
                                                Creating Account...
                                            </div>
                                        ) : (
                                            "Create Account"
                                        )}
                                    </motion.button>
                                    <motion.p 
                                        className="text-sm font-light text-gray-500"
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ delay: 0.5 }}
                                    >
                                        Already have an account?{" "}
                                        <Link to="/login" className="font-medium text-gray-400 hover:text-blue-500 hover:underline transition-colors duration-300">
                                            Login here
                                        </Link>
                                    </motion.p>
                                </div>
                            </div>

                            {/* Responsive Terms and Conditions Dialog */}
                            <Dialog
                                fullScreen={fullScreen}
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="terms-dialog-title"
                                PaperProps={{
                                    style: {
                                        backgroundColor: 'black',
                                        color: '#e5e7eb',
                                        borderRadius: '8px',
                                        border: '1px solid #4b5563',
                                        maxWidth: '90%',
                                        maxHeight: '90%',
                                        width: fullScreen ? '100%' : '800px',
                                        margin: '16px'
                                    },
                                }}
                            >
                                <DialogTitle id="terms-dialog-title" style={{ borderBottom: '1px solid #374151' }}>
                                    <div className="font-bold text-xl text-gray-200">Terms and Conditions</div>
                                </DialogTitle>
                                <DialogContent dividers sx={{ borderColor: '#374151', padding: 0 }}>
                                    <div className="overflow-y-auto p-4 md:p-6">
                                        <div className="max-w-4xl mx-auto">
                                            <div className="mb-6">
                                                <h2 className="text-xl font-semibold text-gray-200 mb-2">1. Introduction</h2>
                                                <p className="text-gray-400 leading-relaxed text-sm md:text-base">
                                                    Welcome to Blockchain Pathshala ("we", "our", "us"). By accessing or using our website and services (collectively, the "Service"), you agree to be bound by these Terms and Conditions ("Terms"). If you do not agree to these Terms, you may not use the Service.
                                                </p>
                                            </div>

                                            <div className="mb-6">
                                                <h2 className="text-xl font-semibold text-gray-200 mb-2">2. Use of the Service</h2>
                                                <p className="text-gray-400 leading-relaxed text-sm md:text-base">
                                                    You agree to use the Service only for lawful purposes and in accordance with these Terms. You must not use the Service in any way that could damage, disable, overburden, or impair the Service, or interfere with any other party's use of the Service.
                                                </p>
                                            </div>

                                            <div className="mb-6">
                                                <h2 className="text-xl font-semibold text-gray-200 mb-2">3. Account Registration</h2>
                                                <p className="text-gray-400 leading-relaxed text-sm md:text-base">
                                                    To access certain features of the Service, you may need to register for an account. You must provide accurate and complete information during the registration process and keep your account information updated. You are responsible for safeguarding your account credentials and for all activities that occur under your account.
                                                </p>
                                            </div>

                                            <div className="mb-6">
                                                <h2 className="text-xl font-semibold text-gray-200 mb-2">4. Courses and Content</h2>
                                                <p className="text-gray-400 leading-relaxed text-sm md:text-base">
                                                    All courses and content provided by Blockchain Pathshala are for educational purposes only. We make no guarantees regarding the results or outcomes from using our courses and content.
                                                </p>
                                            </div>

                                            <div className="mb-6">
                                                <h2 className="text-xl font-semibold text-gray-200 mb-2">5. Payment and Refund Policy</h2>
                                                <h3 className="text-lg font-semibold text-gray-400 mt-4">Payment</h3>
                                                <p className="text-gray-400 leading-relaxed text-sm md:text-base">
                                                    All payments for courses must be made in advance. We accept various payment methods as indicated on our website.
                                                </p>
                                                <h3 className="text-lg font-semibold text-gray-200 mt-4">Refund Policy</h3>
                                                <p className="text-gray-400 leading-relaxed text-sm md:text-base">
                                                    We offer a 7-day refund policy for all courses. If you are not satisfied with a course, you may request a refund within 7 days of purchase. To request a refund, please contact our support team at the provided support email. Refunds will be processed within 15-30 business days.
                                                </p>
                                            </div>

                                            {/* Additional terms continue... */}
                                            <div className="mb-6">
                                                <h2 className="text-xl font-semibold text-gray-200 mb-2">6. Placement Support and Assistance</h2>
                                                <p className="text-gray-400 leading-relaxed text-sm md:text-base">
                                                    For participants of our Full-Stack Program, Blockchain Pathshala offers placement support and assistance for a period of 3 months after the completion of the program. This includes:
                                                </p>
                                                <ul className="list-disc list-inside mt-2 text-gray-400 leading-relaxed text-sm md:text-base">
                                                    <li><strong>Guaranteed Internship:</strong> Participants are guaranteed an internship placement for a duration of 3 months.</li>
                                                    <li><strong>Stipend:</strong> The stipend for the internship is determined by the company providing the internship.</li>
                                                </ul>
                                                <p className="text-gray-400 mt-4 text-sm md:text-base">
                                                    Blockchain Pathshala does not guarantee full-time employment post-internship. The terms of the internship, including responsibilities, working hours, and other conditions, are determined by the company offering the internship.
                                                </p>
                                            </div>

                                            <div className="mb-6">
                                                <h2 className="text-xl font-semibold text-gray-200 mb-2">7. Intellectual Property</h2>
                                                <p className="text-gray-400 leading-relaxed text-sm md:text-base">
                                                    All content, including but not limited to text, graphics, images, and software, is the property of Blockchain Pathshala or its licensors and is protected by intellectual property laws. You may not use, reproduce, or distribute any content without our prior written permission.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </DialogContent>
                                <DialogActions sx={{ padding: '16px', justifyContent: 'space-between', borderTop: '1px solid #374151' }}>
                                    <Button 
                                        onClick={handleClose} 
                                        color="error" 
                                        variant="outlined"
                                        sx={{
                                            '&:hover': {
                                                backgroundColor: 'rgba(239, 68, 68, 0.1)',
                                            }
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button 
                                        onClick={handleAcceptTerms} 
                                        color="primary" 
                                        variant="contained"
                                        sx={{
                                            backgroundImage: 'linear-gradient(to right, #3b82f6, #ec4899)',
                                            '&:hover': {
                                                opacity: 0.9,
                                            }
                                        }}
                                    >
                                        Accept Terms
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </form>
                    </div>
                </motion.div>
            </div>
        </section>
    );
}
