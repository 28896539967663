import React, { useContext, useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { PencilIcon, UserIcon, ClockIcon, ShareIcon, BookmarkIcon, HeartIcon, ChatBubbleLeftIcon } from "@heroicons/react/20/solid";
import AuthContext from "../context/AuthContext";
import myConfig from "../configs/config";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import TextField from '@mui/material/TextField';
import { createTheme, Skeleton, ThemeProvider, Tooltip, IconButton } from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { Bounce, ToastContainer, toast } from "react-toastify";
import { motion } from "framer-motion";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 6,
    whiteSpace: 'nowrap',
    width: 1,
  });

export function ReadBlog() {
    const [blogs, setBlogs] = useState(null); // Default to null to differentiate between loading and empty state
    const { authTokens, user, logoutUser } = useContext(AuthContext);
    const { BlogId } = useParams();
    const [is_edit, set_isEdit] = useState(false);
    const [value, setValue] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [title, setTitle] = useState('');
    const navigate = useNavigate();

    // New state variables for interactive features
    const [readingProgress, setReadingProgress] = useState(0);
    const [liked, setLiked] = useState(false);
    const [likeCount, setLikeCount] = useState(0);
    const [showTableOfContents, setShowTableOfContents] = useState(false);
    const [tableOfContents, setTableOfContents] = useState([]);
    const [readingTime, setReadingTime] = useState(0);
    const blogContentRef = useRef(null);

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        },
    });

    const [preview, setPreview] = useState(null);
    
        
    const modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline','strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link', 'image'],
            ['clean']
        ],
    };

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
    ];

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedFile(file);
            setPreview(URL.createObjectURL(file)); // Set the preview URL
        }
    };

    useEffect(() => {
        const getBlog = async () => {
            console.log("Getting individual blog", BlogId);
            const blogUrl = `${myConfig.CRU_URL}/account/blog/${BlogId}/`;
            try {
                const response = await axios.get(blogUrl, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authTokens.access}`,
                    },
                });
                console.log(response.data);
                setBlogs(response.data);
                setTitle(response.data.title);
                setValue(response.data.description);
            } catch (error) {
                console.error("Error fetching blogs", error);
            }
        };

        if (authTokens && BlogId) { // Check that authTokens and BlogId are available
            getBlog();
        }else{
            logoutUser();
        }
    }, [authTokens, BlogId]); // Add BlogId to the dependency array

    // Edit Blog
    const handleEdit = () => {
        const blogUrl = `${myConfig.CRU_URL}/account/blog/`;
        console.log(title, value, selectedFile);
        if ( title === '' || value === '' || BlogId === null) {
            alert('Please fill all fields');
            return;
        }
        let form_data = new FormData();
        form_data.append('title', title);
        form_data.append('description', value);
        if(selectedFile){
            form_data.append('image', selectedFile);
        }
        form_data.append('id', BlogId);
        form_data.append('user', String(authTokens.access));
        console.log(form_data);
        
        // Replace toast.progress with toast.loading
        const toastId = toast.loading('Processing! Please wait...', {
            position: "top-right",
            theme: "dark",
        });
        
        axios({
            baseURL: blogUrl,
            method: 'PUT',
            headers: {
                'Content-Type':'multipart/form-data',
                'Authorization': `Bearer ${authTokens.access}`
            },
            data: form_data,
        })
        .then((res) => {
            console.log(res.data);
            if(res.status === 200){
                console.log("Blog Finally Done");
                
                // Update the loading toast to success
                toast.update(toastId, {
                    render: 'Blog Edited 👍',
                    type: 'success',
                    isLoading: false,
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "dark",
                    transition: Bounce,
                });
                
                navigate('/blogs');
            } else if(res.statusText === 'Unauthorized'){
                // Update toast to error and logout
                toast.update(toastId, {
                    render: 'Unauthorized. Please login again.',
                    type: 'error',
                    isLoading: false,
                    autoClose: 5000,
                });
                logoutUser();
            }
        })
        .catch((error) => {
            // Update toast to show error
            toast.update(toastId, {
                render: error.response?.status === 401 
                    ? 'Unauthorized. Please login again.' 
                    : 'Error editing blog. Please try again.',
                type: 'error',
                isLoading: false,
                autoClose: 5000,
            });
            
            if (error.response?.status === 401) {
                // handle 401 error here
                logoutUser();
            }
        });
    }

    // Calculate reading time based on content
    const calculateReadingTime = (content) => {
        // Average reading speed: 200 words per minute
        if (!content) return 0;
        
        const strippedContent = content.replace(/<[^>]+>/g, '');
        const words = strippedContent.split(/\s+/).length;
        const time = Math.ceil(words / 200);
        return time;
    };
    
    // Track reading progress
    useEffect(() => {
        const handleScroll = () => {
            if (!blogContentRef.current) return;
            
            const element = blogContentRef.current;
            const totalHeight = element.scrollHeight - element.clientHeight;
            const windowScrollTop = window.scrollY;
            
            if (windowScrollTop === 0) {
                setReadingProgress(0);
                return;
            }
            
            if (windowScrollTop >= totalHeight) {
                setReadingProgress(100);
                return;
            }
            
            setReadingProgress((windowScrollTop / totalHeight) * 100);
        };
        
        window.addEventListener('scroll', handleScroll);
        
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [blogs]);
    
    // Generate table of contents from blog content
    useEffect(() => {
        if (blogs?.description) {
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = blogs.description;
            const headings = tempDiv.querySelectorAll('h1, h2, h3');
            
            const toc = Array.from(headings).map((heading, index) => ({
                id: `heading-${index}`,
                text: heading.textContent,
                level: parseInt(heading.tagName.substring(1)),
            }));
            
            setTableOfContents(toc);
            
            // Calculate reading time
            setReadingTime(calculateReadingTime(blogs.description));
        }
    }, [blogs]);
    
    // Function to handle share - simplified implementation
    const handleShare = () => {
        try {
            // Create the URL with current domain and blog ID
            const shareUrl = `${window.location.protocol}//${window.location.host}/blog/${BlogId}`;
            console.log("Sharing URL:", shareUrl);
            
            // Create temporary input element
            const tempInput = document.createElement("input");
            tempInput.style.position = "absolute";
            tempInput.style.left = "-1000px";
            tempInput.style.top = "-1000px";
            tempInput.value = shareUrl;
            document.body.appendChild(tempInput);
            
            // Select and copy
            tempInput.select();
            tempInput.setSelectionRange(0, 99999); // For mobile devices
            
            // Try the simpler approach first - execCommand
            const success = document.execCommand("copy");
            
            // Remove the temporary element
            document.body.removeChild(tempInput);
            
            if (success) {
                // Show success toast
                toast.success('Link copied to clipboard!', {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                });
                
                // Visual feedback on the share icon
                const shareIcon = document.querySelector('.share-icon');
                if (shareIcon) {
                    shareIcon.classList.add('text-blue-500');
                    setTimeout(() => {
                        shareIcon.classList.remove('text-blue-500');
                    }, 1000);
                }
                return;
            }
            
            // If execCommand failed, try clipboard API as fallback
            if (navigator.clipboard && window.isSecureContext) {
                navigator.clipboard.writeText(shareUrl).then(() => {
                    toast.success('Link copied to clipboard!');
                }).catch(err => {
                    console.error('Clipboard API error:', err);
                    // Show manual copy dialog as last resort
                    showManualCopyDialog(shareUrl);
                });
            } else {
                // Not secure context or no clipboard API
                showManualCopyDialog(shareUrl);
            }
            
        } catch (error) {
            console.error("Share error:", error);
            // Final fallback - show dialog with URL
            showManualCopyDialog(`${window.location.protocol}//${window.location.host}/blog/${BlogId}`);
        }
    };
    
    // Helper function to show manual copy dialog
    const showManualCopyDialog = (url) => {
        // Create a modal overlay
        const overlay = document.createElement('div');
        overlay.style.position = 'fixed';
        overlay.style.top = '0';
        overlay.style.left = '0';
        overlay.style.width = '100%';
        overlay.style.height = '100%';
        overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
        overlay.style.zIndex = '9999';
        overlay.style.display = 'flex';
        overlay.style.justifyContent = 'center';
        overlay.style.alignItems = 'center';
        
        // Create dialog box
        const dialog = document.createElement('div');
        dialog.style.backgroundColor = '#1f2937';
        dialog.style.padding = '20px';
        dialog.style.borderRadius = '8px';
        dialog.style.maxWidth = '90%';
        dialog.style.width = '500px';
        dialog.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.1)';
        
        dialog.innerHTML = `
            <h3 style="color: white; font-size: 18px; margin-bottom: 15px;">Copy this link to share</h3>
            <div style="display: flex; margin-bottom: 15px;">
                <input id="share-url-input" value="${url}" style="flex: 1; padding: 8px; border-radius: 4px; border: 1px solid #374151; background: #111827; color: white;" readonly>
                <button id="manual-copy-btn" style="margin-left: 10px; background: #3b82f6; color: white; padding: 8px 15px; border: none; border-radius: 4px; cursor: pointer;">Copy</button>
            </div>
            <button id="close-dialog-btn" style="background: transparent; border: 1px solid #4b5563; color: white; padding: 8px 15px; border-radius: 4px; cursor: pointer; float: right;">Close</button>
        `;
        
        overlay.appendChild(dialog);
        document.body.appendChild(overlay);
        
        // Set up event listeners
        document.getElementById('manual-copy-btn').addEventListener('click', () => {
            const input = document.getElementById('share-url-input');
            input.select();
            document.execCommand('copy');
            document.getElementById('manual-copy-btn').textContent = 'Copied!';
            
            setTimeout(() => {
                document.body.removeChild(overlay);
            }, 1500);
        });
        
        document.getElementById('close-dialog-btn').addEventListener('click', () => {
            document.body.removeChild(overlay);
        });
        
        // Auto select the text for easy copying
        setTimeout(() => {
            const input = document.getElementById('share-url-input');
            input.select();
        }, 100);
    };
    
    // Function to handle like
    const handleLike = () => {
        setLiked(!liked);
        setLikeCount(liked ? likeCount - 1 : likeCount + 1);
        // Here you would typically make an API call to update the like status
    };
    
    // Function to scroll to heading
    const scrollToHeading = (id) => {
        const index = parseInt(id.split('-')[1]);
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = blogs.description;
        const headings = tempDiv.querySelectorAll('h1, h2, h3');
        
        if (headings[index]) {
            // Find the actual DOM element that matches this heading
            const headingText = headings[index].textContent;
            const blogContent = document.querySelector('.blog-description');
            const allHeadings = blogContent.querySelectorAll('h1, h2, h3');
            
            for (let heading of allHeadings) {
                if (heading.textContent === headingText) {
                    heading.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    break;
                }
            }
        }
        
        setShowTableOfContents(false);
    };

    if (!blogs) {
        return (
            <div className="flex relative justify-center items-center bg-black overflow-y-scroll h-screen">
                <div className="absolute bottom-0 left-0 right-0 top-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:25px_24px]"></div>
                <div className="absolute left-0 right-0 top-0 h-full w-full rounded-3xl bg-[radial-gradient(circle_400px_at_50%_300px,#fbfbfb36,#000)]"></div>

                <div className="w-3/4 relative p-10 my-10 rounded-lg backdrop-blur-sm border border-gray-600">
                    <div className="flex justify-between items-center">
                        <div className="flex items-center gap-x-4">
                        <Skeleton variant="circular" width={48} height={48} sx={{background:"gray"}} />
                        <div className="w-full">
                            <Skeleton width="100%" height={24} sx={{background:"gray"}}/>
                            <Skeleton width="40%" height={20} sx={{background:"gray"}}/>
                        </div>
                        </div>
                    </div>
                    <div className="mt-4">
                        <Skeleton variant="rectangular" height={200} sx={{background:"gray"}}/>
                    </div>
                    <div className="mt-4 items-center justify-center text-gray-300">
                        <Skeleton width="100%" height={100} sx={{background:"gray"}}/>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="flex relative bg-black min-h-screen">
            {/* Progress bar */}
            <div className="fixed top-0 left-0 right-0 h-1 bg-gray-700 z-50">
                <motion.div 
                    className="h-full bg-gradient-to-r from-blue-500 to-pink-500"
                    style={{ width: `${readingProgress}%` }}
                    initial={{ width: "0%" }}
                    animate={{ width: `${readingProgress}%` }}
                />
            </div>
            
            <div className="absolute bottom-0 left-0 right-0 top-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:25px_24px]"></div>
            <div className="absolute left-0 right-0 top-0 h-full w-full rounded-3xl bg-[radial-gradient(circle_400px_at_50%_300px,#fbfbfb36,#000)]"></div>
            
            <div className="md:w-3/4 w-10/12 relative md:p-10 p-5 md:my-10 rounded-lg backdrop-blur-sm border border-gray-600 mx-auto" ref={blogContentRef}>
                <div className="flex justify-between items-center">
                    <div className="flex items-center gap-x-4">
                        <motion.div 
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            className="w-12 h-12 rounded-full bg-gray-800 flex justify-center items-center overflow-hidden"
                        >
                            {blogs.user.user_profile ? (
                                <img className="w-12 h-12" src={`${blogs.user.user_profile}`} alt="User profile" />
                            ) : (
                                <UserIcon className="h-6 w-auto text-gray-200" />
                            )}
                        </motion.div>
                        <div>
                            <motion.h1 
                                initial={{ opacity: 0, y: -20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5 }}
                                className="text-lg font-bold text-gray-200"
                            >
                                {blogs.title}
                            </motion.h1>
                            <div className="flex items-center space-x-2">
                                <p className="text-xs text-gray-200">
                                    Author: <span className="font-bold">{blogs.user.first_name} {blogs.user.last_name}</span>
                                </p>
                                <span className="text-xs text-gray-400">•</span>
                                <div className="flex items-center text-xs text-gray-300">
                                    <ClockIcon className="h-3 w-3 mr-1" />
                                    <span>{readingTime} min read</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="flex space-x-2">
                        {user.user_id === blogs.user.id ? 
                        <>
                        {is_edit? 
                            <motion.div  
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                className="flex items-center md:text-base text-xs group gap-x-2 bg-transparent text-gray-200 px-3 uppercase py-2 rounded-md border border-gray-600 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r hover:from-blue-500 hover:to-pink-500"
                                onClick={(e) => set_isEdit(false)}
                            >
                                <PencilIcon className="w-6 text-gray-200 group-hover:text-indigo-600" />
                                <span>Cancel Edit</span>
                            </motion.div>
                        :
                            <motion.div  
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                className="flex items-center md:text-base text-xs group gap-x-2 bg-transparent text-gray-200 px-3 uppercase py-2 rounded-md border border-gray-600 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r hover:from-blue-500 hover:to-pink-500"
                                onClick={(e) => set_isEdit(true)}
                            >   
                                <PencilIcon className="w-6 text-gray-200 group-hover:text-indigo-600" />
                                <span>Edit Blog</span>
                            </motion.div>
                        }
                        </>
                        :<></> }
                    </div>
                </div>
                
                {!is_edit ?
                <div className="relative">
                    {/* Blog interactive toolbar */}
                    <div className="my-4 flex justify-between items-center border-t border-b border-gray-700 py-3">
                        <div className="flex space-x-4">
                            <Tooltip title={liked ? "Unlike" : "Like"}>
                                <motion.div 
                                    whileTap={{ scale: 0.9 }}
                                    className={`cursor-pointer flex items-center space-x-1 ${liked ? 'text-pink-500' : 'text-gray-400'}`}
                                    onClick={handleLike}
                                >
                                    <HeartIcon className="h-5 w-5" />
                                    <span className="text-sm">{likeCount}</span>
                                </motion.div>
                            </Tooltip>
                            
                            <Tooltip title="Comment">
                                <div className="cursor-pointer flex items-center space-x-1 text-gray-400 hover:text-gray-200">
                                    <ChatBubbleLeftIcon className="h-5 w-5" />
                                    <span className="text-sm">0</span>
                                </div>
                            </Tooltip>
                        </div>
                        
                        <div className="flex space-x-4">
                            <Tooltip title="Table of contents">
                                <div 
                                    className="cursor-pointer text-gray-400 hover:text-gray-200"
                                    onClick={() => setShowTableOfContents(!showTableOfContents)}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12" />
                                    </svg>
                                </div>
                            </Tooltip>
                            
                            <Tooltip title="Share">
                                <motion.div 
                                    whileTap={{ scale: 0.9 }}
                                    className="cursor-pointer relative text-gray-400 hover:text-blue-400 share-icon"
                                    onClick={handleShare}
                                >
                                    <ShareIcon className="h-5 w-5" />
                                    <span className="share-feedback absolute -top-1 -right-1 hidden">
                                        {/* This will be shown/hidden via JS */}
                                    </span>
                                </motion.div>
                            </Tooltip>
                            
                            <Tooltip title="Save">
                                <div className="cursor-pointer text-gray-400 hover:text-gray-200">
                                    <BookmarkIcon className="h-5 w-5" />
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                    
                    {/* Table of contents */}
                    {showTableOfContents && tableOfContents.length > 0 && (
                        <motion.div 
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                            className="bg-gray-900 rounded-lg p-4 mb-6 border border-gray-700"
                        >
                            <h3 className="text-gray-200 font-medium mb-2">Table of Contents</h3>
                            <ul className="space-y-1">
                                {tableOfContents.map((item) => (
                                    <li 
                                        key={item.id}
                                        className="cursor-pointer hover:text-blue-400 text-gray-300"
                                        style={{ paddingLeft: `${(item.level - 1) * 12}px` }}
                                        onClick={() => scrollToHeading(item.id)}
                                    >
                                        {item.text}
                                    </li>
                                ))}
                            </ul>
                        </motion.div>
                    )}
                    
                    <div className="mt-4 relative">
                        <motion.img 
                            initial={{ opacity: 0, scale: 0.95 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.5 }}
                            src={`${blogs.image}`} 
                            alt="blog" 
                            className="h-auto max-w-full object-fill rounded-lg shadow-2xl" 
                        />
                    </div>
                    
                    <motion.div 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                        className="mt-8 text-gray-300 blog-description prose prose-invert lg:prose-lg max-w-none"
                        dangerouslySetInnerHTML={{ __html: blogs.description }}
                    >
                    </motion.div>
                    
                    {/* Call to action section */}
                    <div className="mt-10 pt-6 border-t border-gray-700">
                        <div className="flex flex-col md:flex-row items-center justify-between">
                            <div className="flex items-center space-x-4 mb-4 md:mb-0">
                                <div className="w-12 h-12 rounded-full bg-gray-800 flex justify-center items-center overflow-hidden">
                                    {blogs.user.user_profile ? (
                                        <img className="w-12 h-12" src={`${blogs.user.user_profile}`} alt="User profile" />
                                    ) : (
                                        <UserIcon className="h-6 w-auto text-gray-200" />
                                    )}
                                </div>
                                <div>
                                    <p className="text-gray-200 font-medium">Written by</p>
                                    <p className="text-gray-400">{blogs.user.first_name} {blogs.user.last_name}</p>
                                </div>
                            </div>
                            
                            <div className="flex space-x-3">
                                <motion.button 
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    onClick={handleShare} 
                                    className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-200 flex items-center"
                                >
                                    <ShareIcon className="h-4 w-4 mr-2" />
                                    Share Article
                                </motion.button>
                                <button onClick={() => navigate('/blogs')} className="bg-transparent border border-gray-600 text-gray-200 px-4 py-2 rounded-md hover:bg-gray-800 transition duration-200">
                                    More Articles
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                :
                    <ThemeProvider theme={darkTheme}>
                        {/* ...existing edit mode code... */}
                        {preview && (
                            <div className='flex items-center justify-center' >
                                <img src={preview} alt="Selected preview" className='w-auto h-60 object-fill' />
                            </div>
                        )}
                        <Button component="label" role={undefined} variant="contained" tabIndex={-1} startIcon={<AddPhotoAlternateIcon />} style={{marginTop:'5px'}}>
                            Select Blog Poster
                            <VisuallyHiddenInput type="file" accept="image/png, image/jpeg" onChange={handleFileChange}/>
                        </Button>

                        <Box component="div"  className="mt-5"  noValidate autoComplete="off">
                            <TextField type="text" placeholder="Course Name" label="Enter Blog Name" className="" value={title} onChange={(e) => setTitle(e.target.value)} fullWidth />
                        </Box>

                        <label htmlFor="email" className="block text-sm font-semibold mt-6 leading-6 text-gray-900 ">
                            Write Blog 
                        </label>
                        <div>
                            <ReactQuill className='rounded-lg h-full bg-white' formats={formats} modules={modules} theme="snow" value={value} onChange={setValue}>
                            </ReactQuill>
                        </div>
                        <div className="relative mt-8">
                            <motion.button 
                                whileHover={{ scale: 1.05 }} 
                                whileTap={{ scale: 0.95 }}
                                className="text-gray-200 uppercase border-gray-600 border px-3 py-2 rounded-md font-semibold hover:bg-clip-text hover:text-transparent hover:bg-gradient-to-r hover:from-blue-400 hover:to-pink-600"  
                                onClick={handleEdit} 
                            >
                                Edit Blog
                            </motion.button>
                        </div>
                    </ThemeProvider>
                }
                
                <ToastContainer />
            </div>
        </div>
    );
}
