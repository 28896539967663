import React, { useState, useEffect, useRef } from 'react';
import { FaChevronDown, FaChevronUp, FaSearch, FaArrowUp, FaPrint, FaDownload } from 'react-icons/fa';

export function Policy() {
  // State for collapsible sections
  const [openSections, setOpenSections] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [showBackToTop, setShowBackToTop] = useState(false);
  
  const sectionRefs = useRef({});
  
  // Toggle section collapse/expand
  const toggleSection = (sectionId) => {
    setOpenSections({
      ...openSections,
      [sectionId]: !openSections[sectionId]
    });
  };
  
  // Scroll to section from table of contents
  const scrollToSection = (sectionId) => {
    if (sectionRefs.current[sectionId]) {
      sectionRefs.current[sectionId].scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Handle search functionality
  useEffect(() => {
    if (searchTerm.trim() === '') {
      setSearchResults([]);
      return;
    }
    
    const results = [];
    document.querySelectorAll('.policy-content p, .policy-content h2, .policy-content h3').forEach(el => {
      if (el.textContent.toLowerCase().includes(searchTerm.toLowerCase())) {
        const sectionId = el.closest('.policy-section')?.id;
        if (sectionId && !results.includes(sectionId)) {
          results.push(sectionId);
          setOpenSections(prev => ({...prev, [sectionId]: true}));
        }
      }
    });
    
    setSearchResults(results);
  }, [searchTerm]);
  
  // Handle scroll to show/hide back to top button
  useEffect(() => {
    const handleScroll = () => {
      setShowBackToTop(window.pageYOffset > 300);
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  
  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  
  // Print policy function
  const printPolicy = () => {
    window.print();
  };
  
  // Policy sections data for table of contents
  const policySections = [
    { id: 'introduction', title: '1. Introduction' },
    { id: 'use-of-service', title: '2. Use of the Service' },
    { id: 'account-registration', title: '3. Account Registration' },
    { id: 'courses-content', title: '4. Courses and Content' },
    { id: 'payment-refund', title: '5. Payment and Refund Policy' },
    { id: 'placement-support', title: '6. Placement Support and Assistance' },
    { id: 'intellectual-property', title: '7. Intellectual Property' },
    { id: 'limitation-liability', title: '8. Limitation of Liability' },
    { id: 'governing-law', title: '9. Governing Law' },
    { id: 'changes-terms', title: '10. Changes to Terms' },
    { id: 'privacy-policy', title: '11. Privacy Policy' },
    { id: 'contact-us', title: '12. Contact Us' },
  ];
  
  return (
    <div className='bg-black text-gray-300 min-h-screen'>
      <div className="w-10/12 mx-auto p-5">
        <h1 className="text-3xl font-bold text-center mb-8">Terms and Conditions</h1>
        
        {/* Action buttons */}
        {/* <div className="flex justify-end mb-6 space-x-4">
          <button 
            className="flex items-center bg-gray-700 hover:bg-gray-600 text-white px-4 py-2 rounded"
            onClick={printPolicy}
          >
            <FaPrint className="mr-2" /> Print
          </button>
          <button 
            className="flex items-center bg-gray-700 hover:bg-gray-600 text-white px-4 py-2 rounded"
            onClick={() => window.alert("PDF download would be implemented here")}
          >
            <FaDownload className="mr-2" /> Download PDF
          </button>
        </div> */}
        
        {/* Search bar */}
        {/* <div className="mb-8 relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <FaSearch className="text-gray-400" />
          </div>
          <input
            type="text"
            placeholder="Search in policy..."
            className="bg-gray-800 text-white w-full pl-10 pr-4 py-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div> */}
        
        {/* Table of Contents */}
        <div className="mb-8 bg-gray-800 p-4 rounded">
          <h2 className="text-xl font-semibold mb-4">Table of Contents</h2>
          <ul className="space-y-2">
            {policySections.map((section) => (
              <li key={section.id}>
                <button 
                  onClick={() => scrollToSection(section.id)}
                  className="text-blue-400 hover:text-blue-300 text-left"
                >
                  {section.title}
                </button>
              </li>
            ))}
          </ul>
        </div>
        
        {/* Policy Content with Collapsible Sections */}
        <div className="policy-content">
          {/* Section 1 */}
          <div 
            id="introduction"
            className="policy-section mb-6 bg-gray-800 rounded overflow-hidden"
            ref={el => sectionRefs.current['introduction'] = el}
          >
            <div 
              className="flex justify-between items-center p-4 cursor-pointer bg-gray-700"
              onClick={() => toggleSection('introduction')}
            >
              <h2 className="text-xl font-semibold">1. Introduction</h2>
              {openSections['introduction'] ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            <div className={`p-4 transition-all duration-300 ${openSections['introduction'] ? 'block' : 'hidden'}`}>
              <p>Welcome to Blockchain Pathshala ("we", "our", "us"). By accessing or using our website and services (collectively, the "Service"), you agree to be bound by these Terms and Conditions ("Terms"). If you do not agree to these Terms, you may not use the Service.</p>
            </div>
          </div>
          
          {/* Section 2 */}
          <div 
            id="use-of-service"
            className="policy-section mb-6 bg-gray-800 rounded overflow-hidden"
            ref={el => sectionRefs.current['use-of-service'] = el}
          >
            <div 
              className="flex justify-between items-center p-4 cursor-pointer bg-gray-700"
              onClick={() => toggleSection('use-of-service')}
            >
              <h2 className="text-xl font-semibold">2. Use of the Service</h2>
              {openSections['use-of-service'] ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            <div className={`p-4 transition-all duration-300 ${openSections['use-of-service'] ? 'block' : 'hidden'}`}>
              <p>You agree to use the Service only for lawful purposes and in accordance with these Terms. You must not use the Service in any way that could damage, disable, overburden, or impair the Service, or interfere with any other party's use of the Service.</p>
            </div>
          </div>
          
          {/* Section 3 */}
          <div 
            id="account-registration"
            className="policy-section mb-6 bg-gray-800 rounded overflow-hidden"
            ref={el => sectionRefs.current['account-registration'] = el}
          >
            <div 
              className="flex justify-between items-center p-4 cursor-pointer bg-gray-700"
              onClick={() => toggleSection('account-registration')}
            >
              <h2 className="text-xl font-semibold">3. Account Registration</h2>
              {openSections['account-registration'] ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            <div className={`p-4 transition-all duration-300 ${openSections['account-registration'] ? 'block' : 'hidden'}`}>
              <p>To access certain features of the Service, you may need to register for an account. You must provide accurate and complete information during the registration process and keep your account information updated. You are responsible for safeguarding your account credentials and for all activities that occur under your account.</p>
            </div>
          </div>
          
          {/* Continue with remaining sections in the same pattern */}
          {/* Section 4 - Courses and Content */}
          <div 
            id="courses-content"
            className="policy-section mb-6 bg-gray-800 rounded overflow-hidden"
            ref={el => sectionRefs.current['courses-content'] = el}
          >
            <div 
              className="flex justify-between items-center p-4 cursor-pointer bg-gray-700"
              onClick={() => toggleSection('courses-content')}
            >
              <h2 className="text-xl font-semibold">4. Courses and Content</h2>
              {openSections['courses-content'] ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            <div className={`p-4 transition-all duration-300 ${openSections['courses-content'] ? 'block' : 'hidden'}`}>
              <p>All courses and content provided by Blockchain Pathshala are for educational purposes only. We make no guarantees regarding the results or outcomes from using our courses and content.</p>
            </div>
          </div>
          
          {/* Section 5 - Payment and Refund */}
          <div 
            id="payment-refund"
            className="policy-section mb-6 bg-gray-800 rounded overflow-hidden"
            ref={el => sectionRefs.current['payment-refund'] = el}
          >
            <div 
              className="flex justify-between items-center p-4 cursor-pointer bg-gray-700"
              onClick={() => toggleSection('payment-refund')}
            >
              <h2 className="text-xl font-semibold">5. Payment and Refund Policy</h2>
              {openSections['payment-refund'] ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            <div className={`p-4 transition-all duration-300 ${openSections['payment-refund'] ? 'block' : 'hidden'}`}>
              <h3 className="text-lg font-medium mb-2">Payment</h3>
              <p>All payments for courses must be made in advance. We accept various payment methods as indicated on our website.</p>
              
              <h3 className="text-lg font-medium mb-2 mt-4">Refund Policy</h3>
              <p>We don't have refund policy.</p>
            </div>
          </div>
          
          {/* Section 6 - Placement Support and Assistance */}
          <div 
            id="placement-support"
            className="policy-section mb-6 bg-gray-800 rounded overflow-hidden"
            ref={el => sectionRefs.current['placement-support'] = el}
          >
            <div 
              className="flex justify-between items-center p-4 cursor-pointer bg-gray-700"
              onClick={() => toggleSection('placement-support')}
            >
              <h2 className="text-xl font-semibold">6. Placement Support and Assistance</h2>
              {openSections['placement-support'] ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            <div className={`p-4 transition-all duration-300 ${openSections['placement-support'] ? 'block' : 'hidden'}`}>
              <h3 className="text-lg font-medium mb-2">Full-Stack Blockchain Program</h3>
              <p>For participants of our Full-Stack Program, Blockchain Pathshala offers placement support and assistance for a period of 3 months after the completion of the program. This includes:</p>
              <ul>
                <li>Guaranteed Internship: Participants are guaranteed an internship placement for a duration of 3 months.</li>
                <li>Stipend: The stipend for the internship is determined by the company providing the internship.</li>
              </ul>
              <p>Blockchain Pathshala does not guarantee full-time employment post-internship. The terms of the internship, including responsibilities, working hours, and other conditions, are determined by the company offering the internship.</p>
            </div>
          </div>
          
          {/* Section 7 - Intellectual Property */}
          <div 
            id="intellectual-property"
            className="policy-section mb-6 bg-gray-800 rounded overflow-hidden"
            ref={el => sectionRefs.current['intellectual-property'] = el}
          >
            <div 
              className="flex justify-between items-center p-4 cursor-pointer bg-gray-700"
              onClick={() => toggleSection('intellectual-property')}
            >
              <h2 className="text-xl font-semibold">7. Intellectual Property</h2>
              {openSections['intellectual-property'] ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            <div className={`p-4 transition-all duration-300 ${openSections['intellectual-property'] ? 'block' : 'hidden'}`}>
              <p>All content, including but not limited to text, graphics, images, and software, is the property of Blockchain Pathshala or its licensors and is protected by intellectual property laws. You may not use, reproduce, or distribute any content without our prior written permission.</p>
            </div>
          </div>
          
          {/* Section 8 - Limitation of Liability */}
          <div 
            id="limitation-liability"
            className="policy-section mb-6 bg-gray-800 rounded overflow-hidden"
            ref={el => sectionRefs.current['limitation-liability'] = el}
          >
            <div 
              className="flex justify-between items-center p-4 cursor-pointer bg-gray-700"
              onClick={() => toggleSection('limitation-liability')}
            >
              <h2 className="text-xl font-semibold">8. Limitation of Liability</h2>
              {openSections['limitation-liability'] ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            <div className={`p-4 transition-all duration-300 ${openSections['limitation-liability'] ? 'block' : 'hidden'}`}>
              <p>To the fullest extent permitted by law, Blockchain Pathshala shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from (i) your use or inability to use the Service; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein.</p>
            </div>
          </div>
          
          {/* Section 9 - Governing Law */}
          <div 
            id="governing-law"
            className="policy-section mb-6 bg-gray-800 rounded overflow-hidden"
            ref={el => sectionRefs.current['governing-law'] = el}
          >
            <div 
              className="flex justify-between items-center p-4 cursor-pointer bg-gray-700"
              onClick={() => toggleSection('governing-law')}
            >
              <h2 className="text-xl font-semibold">9. Governing Law</h2>
              {openSections['governing-law'] ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            <div className={`p-4 transition-all duration-300 ${openSections['governing-law'] ? 'block' : 'hidden'}`}>
              <p>These Terms shall be governed and construed in accordance with the laws of India, without regard to its conflict of law provisions. Any disputes arising under or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts located in Sangamner, Maharashtra.</p>
            </div>
          </div>
          
          {/* Section 10 - Changes to Terms */}
          <div 
            id="changes-terms"
            className="policy-section mb-6 bg-gray-800 rounded overflow-hidden"
            ref={el => sectionRefs.current['changes-terms'] = el}
          >
            <div 
              className="flex justify-between items-center p-4 cursor-pointer bg-gray-700"
              onClick={() => toggleSection('changes-terms')}
            >
              <h2 className="text-xl font-semibold">10. Changes to Terms</h2>
              {openSections['changes-terms'] ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            <div className={`p-4 transition-all duration-300 ${openSections['changes-terms'] ? 'block' : 'hidden'}`}>
              <p>We reserve the right to modify or replace these Terms at any time. We will notify you of any changes by posting the new Terms on our website. Your continued use of the Service after any such changes constitutes your acceptance of the new Terms.</p>
            </div>
          </div>
          
          {/* Section 11 - Privacy Policy */}
          <div 
            id="privacy-policy"
            className="policy-section mb-6 bg-gray-800 rounded overflow-hidden"
            ref={el => sectionRefs.current['privacy-policy'] = el}
          >
            <div 
              className="flex justify-between items-center p-4 cursor-pointer bg-gray-700"
              onClick={() => toggleSection('privacy-policy')}
            >
              <h2 className="text-xl font-semibold">11. Privacy Policy</h2>
              {openSections['privacy-policy'] ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            <div className={`p-4 transition-all duration-300 ${openSections['privacy-policy'] ? 'block' : 'hidden'}`}>
              <h3 className="text-lg font-medium mb-2">Introduction</h3>
              <p>This Privacy Policy describes how NEURANOVA TECHNOLOGIES PRIVATE LIMITED and its affiliates (collectively NEURANOVA TECHNOLOGIES PRIVATE LIMITED, we, our) collect, use, share, protect or otherwise process your information/personal data through our website blockchainpathshala.com (hereinafter referred to as Platform). Please note that you may be able to browse certain sections of the Platform without registering with us. We do not offer any product/service under this Platform outside India and your personal data will primarily be stored and processed in India. By visiting this Platform, providing your information or availing any product/service offered on the Platform, you expressly agree to be bound by the terms and conditions of this Privacy Policy, the Terms of Use and the applicable service/product terms and conditions, and agree to be governed by the laws of India including but not limited to the laws applicable to data protection and privacy. If you do not agree please do not use or access our Platform.</p>
              
              <h3 className="text-lg font-medium mb-2 mt-4">Collection</h3>
              <p>We collect your personal data when you use our Platform, services or otherwise interact with us during the course of our relationship and related information provided from time to time. Some of the information that we may collect includes but is not limited to personal data/information provided to us during sign-up/registering or using our Platform such as name, date of birth, address, telephone/mobile number, email ID and/or any such information shared as proof of identity or address. Some of the sensitive personal data may be collected with your consent, such as your bank account or credit or debit card or other payment instrument information or biometric information such as your facial features or physiological information (in order to enable use of certain features when opted for, available on the Platform) etc all of the above being in accordance with applicable law(s).</p>
              <p>You always have the option to not provide information, by choosing not to use a particular service or feature on the Platform. We may track your behaviour, preferences, and other information that you choose to provide on our Platform. This information is compiled and analysed on an aggregated basis. We will also collect your information related to your transactions on Platform and such third-party business partner platforms. When such a third-party business partner collects your personal data directly from you, you will be governed by their privacy policies. We shall not be responsible for the third-party business partner's privacy practices or the content of their privacy policies, and we request you to read their privacy policies prior to disclosing any information.</p>
              <p>If you receive an email, a call from a person/association claiming to be NEURANOVA TECHNOLOGIES PRIVATE LIMITED seeking any personal data like debit/credit card PIN, net-banking or mobile banking password, we request you to never provide such information. If you have already revealed such information, report it immediately to an appropriate law enforcement agency.</p>
              
              <h3 className="text-lg font-medium mb-2 mt-4">Usage</h3>
              <p>We use personal data to provide the services you request. To the extent we use your personal data to market to you, we will provide you the ability to opt-out of such uses. We use your personal data to assist sellers and business partners in handling and fulfilling orders; enhancing customer experience; to resolve disputes; troubleshoot problems; inform you about online and offline offers, products, services, and updates; customise your experience; detect and protect us against error, fraud and other criminal activity; enforce our terms and conditions; conduct marketing research, analysis and surveys; and as otherwise described to you at the time of collection of information. You understand that your access to these products/services may be affected in the event permission is not provided to us.</p>
              
              <h3 className="text-lg font-medium mb-2 mt-4">Sharing</h3>
              <p>We may share your personal data internally within our group entities, our other corporate entities, and affiliates to provide you access to the services and products offered by them. These entities and affiliates may market to you as a result of such sharing unless you explicitly opt-out. We may disclose personal data to third parties such as sellers, business partners, third party service providers including logistics partners, prepaid payment instrument issuers, third-party reward programs and other payment opted by you. These disclosure may be required for us to provide you access to our services and products offered to you, to comply with our legal obligations, to enforce our user agreement, to facilitate our marketing and advertising activities, to prevent, detect, mitigate, and investigate fraudulent or illegal activities related to our services.</p>
              <p>We may disclose personal and sensitive personal data to government agencies or other authorised law enforcement agencies if required to do so by law or in the good faith belief that such disclosure is reasonably necessary to respond to subpoenas, court orders, or other legal process. We may disclose personal data to law enforcement offices, third party rights owners, or others in the good faith belief that such disclosure is reasonably necessary to: enforce our Terms of Use or Privacy Policy; respond to claims that an advertisement, posting or other content violates the rights of a third party; or protect the rights, property or personal safety of our users or the general public.</p>
              
              <h3 className="text-lg font-medium mb-2 mt-4">Security Precautions</h3>
              <p>To protect your personal data from unauthorised access or disclosure, loss or misuse we adopt reasonable security practices and procedures. Once your information is in our possession or whenever you access your account information, we adhere to our security guidelines to protect it against unauthorised access and offer the use of a secure server. However, the transmission of information is not completely secure for reasons beyond our control. By using the Platform, the users accept the security implications of data transmission over the internet and the World Wide Web which cannot always be guaranteed as completely secure, and therefore, there would always remain certain inherent risks regarding use of the Platform. Users are responsible for ensuring the protection of login and password records for their account.</p>
              
              <h3 className="text-lg font-medium mb-2 mt-4">Data Deletion and Retention</h3>
              <p>You have an option to delete your account by visiting your profile and settings on our Platform, this action would result in you losing all information related to your account. You may also write to us at the contact information provided below to assist you with these requests. We may in event of any pending grievance, claims, pending shipments or any other services we may refuse or delay deletion of the account. Once the account is deleted, you will lose access to the account. We retain your personal data information for a period no longer than is required for the purpose for which it was collected or as required under any applicable law. However, we may retain data related to you if we believe it may be necessary to prevent fraud or future abuse or for other legitimate purposes. We may continue to retain your data in anonymised form for analytical and research purposes.</p>
              
              <h3 className="text-lg font-medium mb-2 mt-4">Your Rights</h3>
              <p>You may access, rectify, and update your personal data directly through the functionalities provided on the Platform.</p>
              
              <h3 className="text-lg font-medium mb-2 mt-4">Consent</h3>
              <p>By visiting our Platform or by providing your information, you consent to the collection, use, storage, disclosure and otherwise processing of your information on the Platform in accordance with this Privacy Policy. If you disclose to us any personal data relating to other people, you represent that you have the authority to do so and permit us to use the information in accordance with this Privacy Policy. You, while providing your personal data over the Platform or any partner platforms or establishments, consent to us (including our other corporate entities, affiliates, lending partners, technology partners, marketing channels, business partners and other third parties) to contact you through SMS, instant messaging apps, call and/or e-mail for the purposes specified in this Privacy Policy.</p>
              <p>You have an option to withdraw your consent that you have already provided by writing to the Grievance Officer at the contact information provided below. Please mention "Withdrawal of consent for processing personal data" in your subject line of your communication. We may verify such requests before acting on our request. However, please note that your withdrawal of consent will not be retrospective and will be in accordance with the Terms of Use, this Privacy Policy, and applicable laws. In the event you withdraw consent given to us under this Privacy Policy, we reserve the right to restrict or deny the provision of our services for which we consider such information to be necessary.</p>
              
              <h3 className="text-lg font-medium mb-2 mt-4">Changes to this Privacy Policy</h3>
              <p>Please check our Privacy Policy periodically for changes. We may update this Privacy Policy to reflect changes to our information practices. We may alert/notify you about the significant changes to the Privacy Policy, in the manner as may be required under applicable laws.</p>
            </div>
          </div>
          
          {/* Section 12 - Contact Us */}
          <div 
            id="contact-us"
            className="policy-section mb-6 bg-gray-800 rounded overflow-hidden"
            ref={el => sectionRefs.current['contact-us'] = el}
          >
            <div 
              className="flex justify-between items-center p-4 cursor-pointer bg-gray-700"
              onClick={() => toggleSection('contact-us')}
            >
              <h2 className="text-xl font-semibold">12. Contact Us</h2>
              {openSections['contact-us'] ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            <div className={`p-4 transition-all duration-300 ${openSections['contact-us'] ? 'block' : 'hidden'}`}>
              <p>If you have any questions about these Terms, please contact through Phone Call 9172325612  or Email
              blockchainpathshala@gmail.com</p>
            </div>
          </div>
        </div>
        
        {/* Back to top button */}
        {showBackToTop && (
          <button 
            className="fixed bottom-8 right-8 bg-blue-600 hover:bg-blue-700 text-white p-3 rounded-full shadow-lg transition-all duration-300"
            onClick={scrollToTop}
            aria-label="Back to top"
          >
            <FaArrowUp />
          </button>
        )}
      </div>
    </div>
  );
}
