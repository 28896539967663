import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import {faLinkedin} from '@fortawesome/free-brands-svg-icons'
import { faSearch, faTimes, faGlobe, faEnvelope, faArrowRight, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from 'react';
// Experts
import proA from "../../assests/experts/proA.png"
import sahil from "../../assests/experts/sahil.png"
import rohan from "../../assests/experts/rohan.png"
const experts = [
    { 
        id: 1, 
        name: "Prof. Aishwarya", 
        expertise: "Blockchain Expert", 
        profile: proA, 
        linkedin: "https://www.linkedin.com/in/aish05", 
        twitter: "", 
        github: "", 
        website: "",
        bio: "Professor with extensive experience in blockchain technologies and distributed systems. Specializes in smart contract development and blockchain architecture."
    },
    { 
        id: 2, 
        name: "Rohan Ramteke", 
        expertise: "Blockchain Expert", 
        profile: rohan, 
        linkedin: "https://www.linkedin.com/in/rohan-ramteke-ba5634237", 
        twitter: "", 
        github: "", 
        website: "",
        bio: "Blockchain developer focusing on decentralized applications and cryptocurrency innovations. Experienced in Ethereum and Solidity development."
    },
    { 
        id: 3, 
        name: "Sahil Shaikh", 
        expertise: "Blockchain Expert", 
        profile: sahil, 
        linkedin: "https://www.linkedin.com/in/sahilshaikhs", 
        twitter: "", 
        github: "", 
        website: "",
        bio: "Blockchain security specialist with deep knowledge of consensus mechanisms and cryptographic protocols. Works on securing blockchain networks and smart contracts."
    },
];

export function Expert() {
    const [isVisible, setIsVisible] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedExpert, setSelectedExpert] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [flippedCard, setFlippedCard] = useState(null);
    const [hoveredButton, setHoveredButton] = useState(null);
    const [buttonClicked, setButtonClicked] = useState(null);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 100); // Delay for animation
        return () => clearTimeout(timer);
    }, []);

    // Filter experts based on search term
    const filteredExperts = experts.filter(expert => 
        expert.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        expert.expertise.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleExpertClick = (expert) => {
        setSelectedExpert(expert);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const handleCardFlip = (id) => {
        if (flippedCard === id) {
            setFlippedCard(null);
        } else {
            setFlippedCard(id);
        }
    };

    const handleButtonClick = (expertId) => {
        setButtonClicked(expertId);
        setTimeout(() => setButtonClicked(null), 500); // Reset after animation completes
    };

    return (
        <div className='items-center justify-center p-4'>
            <div>
                <h4 className='text-3xl font-bold text-center my-8 transition-transform duration-500 hover:scale-110 text-gray-200 hover:bg-clip-text hover:text-transparent hover:bg-gradient-to-r hover:from-blue-500 hover:to-pink-500 w-fit mx-auto'>Our Top Experts</h4>
                
                {/* Search Bar with enhanced interaction */}
                {/* <div className="relative mx-auto max-w-md mb-8 group">
                    <input
                        type="text"
                        placeholder="Search experts..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="w-full px-4 py-2 bg-gray-900 text-gray-200 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300 focus:shadow-lg"
                    />
                    <FontAwesomeIcon 
                        icon={faSearch} 
                        className={`absolute right-3 top-3 transition-all duration-300 ${searchTerm ? 'text-blue-400 rotate-90' : 'text-gray-400'}`}
                    />
                    {searchTerm && (
                        <div className="absolute -bottom-6 left-0 text-xs text-blue-400 animate-pulse">
                            Found: {filteredExperts.length} expert{filteredExperts.length !== 1 ? 's' : ''}
                        </div>
                    )}
                </div> */}
                
                <div className='grid grid-cols-1 md:grid-cols-3 md:mx-4 mx-10 md:gap-12 gap-y-10 md:px-20'>
                    {filteredExperts.map((expert, index) => (
                        <div 
                            key={expert.id} 
                            className={`bg-gray-950 group relative border border-gray-800 rounded-md shadow-xl p-5 h-96 transition-all duration-500 ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'} delay-${index * 100} hover:shadow-lg perspective`}
                            onClick={() => handleCardFlip(expert.id)}
                        >
                            <div className={`relative w-full h-full transition-transform duration-700 transform-style-preserve-3d ${flippedCard === expert.id ? 'rotate-y-180' : ''}`}>
                                {/* Front of Card */}
                                <div className='absolute w-full h-full backface-hidden'>
                                    <div className='relative'>
                                        <img src={expert.profile} alt={expert.name} className='w-36 h-36 top-5 relative mx-auto rounded-full transition-transform duration-300 group-hover:scale-110' />
                                    </div>
                                    <div className='pt-10'>
                                        <h4 className='text-xl font-semibold text-center mt-4 text-gray-200'>{expert.name}</h4>
                                        <p className='text-center font-mono text-sm text-gray-500'>{expert.expertise}</p>
                                    </div>
                                    <div className='absolute bottom-5 left-0 right-0'>
                                        <div className='flex justify-center items-center gap-4 mt-4'>
                                            <button 
                                                onMouseEnter={() => setHoveredButton(`know-${expert.id}`)}
                                                onMouseLeave={() => setHoveredButton(null)}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleExpertClick(expert);
                                                    handleButtonClick(`know-${expert.id}`);
                                                }}
                                                className={`relative p-2 w-2/4 text-gray-200 border text-center border-gray-600 uppercase transition-all duration-300 overflow-hidden 
                                                    ${buttonClicked === `know-${expert.id}` ? 'scale-95' : ''} 
                                                    ${hoveredButton === `know-${expert.id}` ? 'bg-gray-900 border-gray-500' : 'bg-gray-800 hover:bg-gray-700'}`}
                                            >
                                                <span className="relative z-10 flex items-center justify-center gap-2">
                                                    <span>Know More</span>
                                                    <FontAwesomeIcon 
                                                        icon={faArrowRight} 
                                                        className={`transition-all duration-300 
                                                            ${hoveredButton === `know-${expert.id}` ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-4'}`}
                                                    />
                                                </span>
                                                {hoveredButton === `know-${expert.id}` && (
                                                    <span className="absolute inset-0 bg-gradient-to-r from-gray-600/30 to-gray-200/50"></span>
                                                )}
                                            </button>   
                                            {expert.linkedin && (
                                                <a 
                                                    href={expert.linkedin} 
                                                    target="_blank" 
                                                    rel="noopener noreferrer"
                                                    onMouseEnter={() => setHoveredButton(`linkedin-${expert.id}`)}
                                                    onMouseLeave={() => setHoveredButton(null)}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleButtonClick(`linkedin-${expert.id}`);
                                                    }}
                                                    className={`relative flex items-center justify-center transition-all duration-300
                                                        ${buttonClicked === `linkedin-${expert.id}` ? 'scale-90' : 'scale-100'}`}
                                                >
                                                    <FontAwesomeIcon 
                                                        icon={faLinkedin} 
                                                        className={`text-blue-400 text-2xl transition-all duration-300 hover:text-blue-300
                                                            ${hoveredButton === `linkedin-${expert.id}` ? 'text-blue-300 scale-125' : ''}`} 
                                                    />
                                                    {hoveredButton === `linkedin-${expert.id}` && (
                                                        <span className="absolute inset-0 rounded-full bg-white/5"></span>
                                                    )}
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                
                                {/* Back of Card */}
                                <div className='absolute w-full h-full backface-hidden rotate-y-180 bg-gray-900 rounded-md p-5 flex flex-col'>
                                    <h4 className='text-xl font-semibold text-center mb-2 text-gray-200'>{expert.name}</h4>
                                    <p className='text-center font-mono text-sm text-gray-400 mb-4'>{expert.expertise}</p>
                                    <p className='text-gray-300 flex-grow text-sm overflow-y-auto'>{expert.bio}</p>
                                    <div className='mt-4 flex justify-center space-x-4'>
                                        {expert.linkedin && (
                                            <a href={expert.linkedin} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300">
                                                <FontAwesomeIcon icon={faLinkedin} size="lg" />
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                
                {/* Modal for Expert Details with enhanced interactive elements */}
                {showModal && selectedExpert && (
                    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4 animate-fadeIn">
                        <div 
                            className="bg-gray-900 rounded-lg shadow-xl max-w-xl w-full overflow-hidden border border-gray-700 animate-scaleIn"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div className="flex justify-between items-center border-b border-gray-700 p-4">
                                <h3 className="text-xl font-bold text-gray-200">{selectedExpert.name}</h3>
                                <button 
                                    onMouseEnter={() => setHoveredButton('close-modal')}
                                    onMouseLeave={() => setHoveredButton(null)}
                                    onClick={closeModal}
                                    className={`text-gray-400 hover:text-white focus:outline-none p-2 rounded-full transition-all duration-300 
                                        ${hoveredButton === 'close-modal' ? 'bg-red-500/20 rotate-90' : ''}`}
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                            </div>
                            <div className="p-6">
                                <div className="flex items-center mb-6">
                                    <img src={selectedExpert.profile} alt={selectedExpert.name} className="w-24 h-24 rounded-full mr-6" />
                                    <div>
                                        <p className="text-lg font-medium text-gray-200">{selectedExpert.name}</p>
                                        <p className="text-sm text-gray-400">{selectedExpert.expertise}</p>
                                    </div>
                                </div>
                                <div className="mb-6">
                                    <h4 className="text-md font-semibold text-gray-300 mb-2">About</h4>
                                    <p className="text-gray-400">{selectedExpert.bio}</p>
                                </div>
                                <div className="mb-6">
                                    <h4 className="text-md font-semibold text-gray-300 mb-2">Connect</h4>
                                    <div className="flex space-x-4">
                                        {selectedExpert.linkedin && (
                                            <a href={selectedExpert.linkedin} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300 flex items-center">
                                                <FontAwesomeIcon icon={faLinkedin} className="mr-2" />
                                                LinkedIn
                                            </a>
                                        )}
                                        {selectedExpert.website && (
                                            <a href={selectedExpert.website} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300 flex items-center">
                                                <FontAwesomeIcon icon={faGlobe} className="mr-2" />
                                                Website
                                            </a>
                                        )}
                                    </div>
                                </div>
                                <button 
                                    onMouseEnter={() => setHoveredButton('close-button')}
                                    onMouseLeave={() => setHoveredButton(null)}
                                    onClick={closeModal}
                                    className={`w-full p-2 text-white rounded transition-all duration-300 relative overflow-hidden
                                        ${hoveredButton === 'close-button' ? 'bg-blue-700 shadow-lg shadow-blue-500/50' : 'bg-blue-600'}`}
                                >
                                    <span className="relative z-10">Close</span>
                                    {hoveredButton === 'close-button' && (
                                        <span className="absolute inset-0 bg-gradient-to-r from-blue-500/0 via-blue-400/30 to-blue-500/0 animate-shine"></span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}